import { Formik } from "formik";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import Input from "../Input/input.component";
import { useToasts } from "react-toast-notifications";
import { GET_DEPARTMENTS, EDIT_TEAM, ADD_INBOXES, DELETE_INBOXES } from "./query";
import { useMutation } from "@apollo/client";
import { departmentValidationSchema } from "./validation-schema";
import { useState, useEffect } from "react";
import UserInboxSelect from "../UserInboxSelect/user-inbox-select.component";

const EditTeam = ({ isOpen, close, selectedTeam }) => {
	const [editTeam, { data, loading, error }] = useMutation(EDIT_TEAM);
	const { addToast } = useToasts();
	const [dirty, setDirty] = useState(false);
	const [inboxes, setInboxes] = useState([])
	const [addInboxes] = useMutation(ADD_INBOXES)
	const [deleteInboxes] = useMutation(DELETE_INBOXES)

	useEffect(() => {
		if(!selectedTeam?.[0]?.inboxes) return setInboxes([])
		let arr = selectedTeam?.[0]?.inboxes.filter((item) => item.Inbox).map((i) => {
			return {
				value: i.Inbox.InboxId,
				label: i.Inbox.Name
			}
		} )
		setInboxes(arr)
	}, [isOpen])

	const submit = async (values) => {
		let temp = await editTeam({
			variables: {
				name: values.teamName,
				departmentId: selectedTeam[0].id,
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_Department(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.update_msgbox_Department,
								query: GET_DEPARTMENTS,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});
		
		await deleteInboxes({
			variables: {
				department: selectedTeam?.[0].id
			}
		})

		for(let i = 0; i < inboxes.length; i++){
			let obj =  {
				inbox: inboxes[i].value,
				department: selectedTeam?.[0].id,
			}
			await addInboxes({
				variables: obj
			})
		}
		
		addToast("Successfully edited team", {
			appearance: "success",
			autoDismiss: true,
		});
		closeModal();
	};

	const closeModal = () => {
		close();
		setDirty(false);
	};

	return (
		<MsgBoxModal
			isOpen={isOpen}
			cancel={closeModal}
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			header="Edit A Team"
			description="Use the form below to edit a team"
			onRequestClose={closeModal}
			submitId="edit-team"
			submit={submit}
			submitting={loading}
			submitButtonDisabled={dirty === false}
		>
			<Formik
				initialValues={{
					teamName: selectedTeam[0]?.name,
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={departmentValidationSchema}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({
					handleSubmit,
					values,
					errors,
					touched,
					validateForm,
					handleChange,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit} id="edit-team">
						<Input
							placeholder="Team Name"
							required
							name="teamName"
							label="Team Name"
							onChange={(e) => {
								handleChange(e);
								setDirty(true);
							}}
							error={errors.teamName}
							value={values.teamName}
						/>
						<UserInboxSelect
							label="Inboxes"
							name="Inboxes"
							onChange={(e) => {
								setInboxes(e)
								setDirty(true);
							}}
							value={inboxes}
						/>
					</form>
				)}
			</Formik>
		</MsgBoxModal>
	);
};

export default EditTeam;
