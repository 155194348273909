import styled from "styled-components";

export const Container = styled.div`
	margin: 24px;
`;

export const AnalyticsFiltersContainer = styled.div`
	display: flex;
	gap: 6px;
	width: 40%;
	padding-left: 12px;
	padding-right: 12px;
	background-color: ${(props) => props.theme.colours.light_0};
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	border-radius: 6px;
	margin-left: 12px;
`;

export const StyledDataCard = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	border-radius: 6px;
	margin: 12px;
	flex: 1;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	outline: ${(props) =>
		props.selected && `solid 2px ${props.theme.colours.primary}`};
	&:hover {
		background-color: ${(props) => props.theme.colours.light_2};
	}
`;

export const DataContainer = styled.div`
	display: flex;
`;

export const ChartContainer = styled.div`
	flex: 1;
`;

export const Header = styled.div`
	text-align: left;
	margin-left: 12px;
	font-size: 22px;
	font-weight: 400;
	color: black;
`;
