import { gql } from "@apollo/client";

export const GET_UNREAD_CONVERSATIONS = gql`
	query getUnreadConversations {
		msgbox_Conversation_aggregate(
			where: {
				IsRead: { _eq: false }
				IsHidden: { _eq: false }
				Folder: { IsInbox: { _eq: true } }
				Contact: { IsDeleted: { _eq: false } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
