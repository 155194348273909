import * as Yup from "yup";

export const generateValidationScheme = (
	disabledFields,
	naturalChatTemplate,
	newNaturalChatTemplate
) => {
	const stepOneValidationSchema = Yup.object().shape(
		{
			searchBy: Yup.string(),
			contacts: disabledFields.contacts
				? undefined
				: Yup.array().when("searchBy", {
						is: "search-contacts",
						then: Yup.array().min(1, "Contacts are required"),
				  }),
			tags: disabledFields.tags
				? undefined
				: Yup.array().when("searchBy", {
						is: "search-tags",
						then: Yup.array().min(1, "Tags are required"),
				  }),
			templateMessages: disabledFields.templateMessages
				? undefined
				: Yup.object().when("message", (message) => {
						return !message ||
							!(naturalChatTemplate || newNaturalChatTemplate)
							? Yup.object().required(
									"Template message is required"
							  )
							: Yup.object();
				  }),
			account: disabledFields.account
				? undefined
				: Yup.object().required("Account is required"),
			message: Yup.string().when(
				"templateMessages",
				(templateMessages) => {
					return !templateMessages
						? Yup.string().required("Message is required")
						: Yup.string();
				}
			),
		},
		[["templateMessages", "message"]]
	);

	const stepTwoValidationSchema = Yup.object().shape({
		variables: Yup.array().of(
			Yup.object().shape({
				value: Yup.string().required("is required"),
				label: Yup.string(),
			})
		),
		attachments: Yup.array()
		.test(
		  'attachment-check',
		  'Attachment is required',
		  function(value) {
			const { templateMessages } = this.parent; 
			if (
			  templateMessages?.value?.attachment && 
			  templateMessages.value.attachment !== "None" && 
			  (!value || value.length === 0)
			) {
				console.log("fallsslslsl")
			  return false;
			}
			return true;
		  }
		)
	});

	return [stepOneValidationSchema, stepTwoValidationSchema];
};
