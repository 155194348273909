import { ConversationFiltersButton } from "../ConversationFilters/styles";
import { useState, useEffect, useRef, createRef } from "react";
import Modal from "react-modal";
import { GET_PHONE_NUMBERS } from "./query";
import { useQuery } from "@apollo/client";
import { getUserId } from "../../helpers/functions";
import Button from "../Button/button.component";
import { Inboxes } from "./styles";
import ShareNumberCard from "../ShareNumberCard/share-number.component";

const WhatsAppShare = () => {
	const { data, error, loading } = useQuery(GET_PHONE_NUMBERS, {
		variables: {
			userId: getUserId(),
		},
	});

	const [whatsAppShareInfoOpen, setWhatsAppShareInfoOpen] = useState(false);
	const [links, setLinks] = useState([]);
	const [filter, setFilter] = useState("");

	const uniqueNames =
		links.length > 0
			? [
					...new Set(
						links.map((item) => item.Name.split(" - ")[0]).sort()
					),
			  ]
			: [];
	const filteredLinks =
		filter.length > 1
			? links.filter((item) => item.Name.split(" - ").includes(filter))
			: links;
	const handleFilterChange = (event) => {
		setFilter(event.value);
	};

	const base = "https://wa.me/";

	useEffect(() => {
		if (!data) return;
		console.log("data:", data)
		let arr = [];

		let fname = data.msgbox_User[0].FirstName;
		let lname = data.msgbox_User[0].LastName;
		data.msgbox_User[0].UserInboxes.forEach((item) => {
			if (!item.Inbox) return;
			let str = encodeURI(
				`?text=Please connect me to ${fname} ${lname}.`
			);
			const url = base + item.Inbox.APIAccount.PhoneNumber.slice(1) + str;

			arr.push({
				Name: item.Inbox.Name,
				url: url,
				number: item.Inbox.APIAccount.PhoneNumber,
			});
		});

		data.msgbox_User[0].UserDepartments.forEach((item) => {
			let name = item.Department.Name;
			item.Department.DepartmentInboxes.forEach((item1) => {
				if (!item1.Inbox) {
					// this is team link across all inboxes
					data.msgbox_User[0].UserInboxes.forEach((item) => {
						if (!item.Inbox) return;
						let str = encodeURI(
							`?text=Please connect me to the ${name} team.`
						);
						const url = base + item.Inbox.APIAccount.PhoneNumber.slice(1) + str;
			
						arr.push({
							Name: item.Inbox.Name + " - " + name,
							url: url,
							number: item.Inbox.APIAccount.PhoneNumber,
							inboxName: item.Inbox.Name,	
						});
					});
				} else {
					// this is a team link specific to this inbox
					let str = encodeURI(
						`?text=Please connect me to the ${name} team.`
					);
					const url =
						base + item1.Inbox.APIAccount.PhoneNumber.slice(1) + str;
					arr.push({
						Name: item1.Inbox.Name + " - " + name,
						url: url,
						number: item1.Inbox.APIAccount.PhoneNumber,
						inboxName: item1.Inbox.Name,	
					});
				}
			});
		});
		const uniqueArray = arr.filter((value, index) => {
			const _value = JSON.stringify(value);
			return index === arr.findIndex(obj => {
			  return JSON.stringify(obj) === _value;
			});
		  });
		console.log("deduped:", uniqueArray)
		setLinks(uniqueArray);
		console.log("arr:", arr)
		if (arr.length > 0) {
			let names = [];
			arr.map((x) => {
				names.push(x.Name);
			});
			setFilter(names.sort()[0].split("-")[0]);
		}
	}, [data]);

	if (links) {
		return (
			<>
				<Modal
					style={{
						content: {
							overflow: "hidden",
							width: "60%",
							top: "50%",
							left: "50%",
							height: "80%",
							transform: "translate(-50%, -50%)",
						},
						overlay: {
							backgroundColor: "rgba(0,0,0,0.7)",
						},
					}}
					onRequestClose={() => setWhatsAppShareInfoOpen(false)}
					isOpen={whatsAppShareInfoOpen}
				>
					<>
						<h2 style={{ margin: "20px", marginBottom: "50px" }}>
							Share your number
						</h2>

						<Inboxes
							placeholder={filter}
							form={false}
							value={filter}
							onChange={handleFilterChange}
							options={uniqueNames.map((name, index) => ({
								key: index,
								value: name,
								label: name,
							}))}
							hasLabel={false}
						/>
						<div
							style={{
								width: "auto",
								height: "65%",
								overflow: "auto",
								padding: "8px",
								boxShadow: "0 0px 8px rgba(0,0,0,0.1)",
							}}
						>
							{filteredLinks.sort().map((item, index) => {
								return (
									<ShareNumberCard
										item={item}
										index={index}
									/>
								);
							})}
						</div>

						<Button
							onClick={() => setWhatsAppShareInfoOpen(false)}
							style={{ float: "right", marginTop: "50px" }}
							buttonName="CLOSE"
							primary
						/>
					</>
				</Modal>
				<ConversationFiltersButton
					data-tip="Open WhatsApp share options"
					onClick={() => setWhatsAppShareInfoOpen(true)}
					icon="fab fa-whatsapp"
				/>
			</>
		);
	} else {
		return null;
	}
};

export default WhatsAppShare;
