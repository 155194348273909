import { createHttpLink, split, ApolloLink, fromPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { SubscriptionClient } from "subscriptions-transport-ws";
import {
	removeAccessToken,
	setAccessToken,
	setUserId,
} from "../helpers/functions";
import { $inboxSubscriptionDisconnected } from "../store";
import { getMainDefinition } from "@apollo/client/utilities";
import jwt_decode from "jwt-decode";
import ReactGA from "react-ga4";

const uri = process.env.REACT_APP_SERVER_URL;

const httpLink = createHttpLink({
	uri: "https://" + uri,
});

const wsLink = new SubscriptionClient("wss://" + uri, {
	reconnect: true,
	connectionParams: () => {
		return {
			headers: {
				"hasura-client-name": "msgboxx-web",
				authorization: "Bearer " + localStorage.getItem("access_token"),
			},
		};
	},
	reconnectionAttempts: 5,
});

wsLink.onConnected(() => {
	//console.log("subscription connected");
	$inboxSubscriptionDisconnected(false);
});

wsLink.onReconnected(() => {
	//console.log("subscription reconnected");
	$inboxSubscriptionDisconnected(false);
});

wsLink.onDisconnected(() => {
	//console.log("subscription disconnected");
	$inboxSubscriptionDisconnected(true);
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("access_token");
	return {
		headers: {
			...headers,
			"hasura-client-name": "msgboxx-web",
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const getNewToken = async () => {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}oauth/refreshtoken`,
			{
				method: "GET",
				credentials: "include",
			}
		);
		const data = await response.json();
		const decoded = jwt_decode(data.access_token);
		setAccessToken(data.access_token);
		setUserId(
			decoded["https://hasura.io/jwt/claims"]["x-hasura-msgbox-id"]
		);
		return data.access_token;
	} catch (error) {
		removeAccessToken();
		document.location.href = `https://${window.location.hostname}/login`;
		console.log("error", error);
	}
};

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (networkError) {
			return fromPromise(getNewToken())
				.filter(async (value) => Boolean(value))
				.flatMap((token) => {
					const oldHeaders = operation.getContext().headers;
					operation.setContext({
						headers: {
							...oldHeaders,
							authorization: `Bearer ${token}`,
						},
					});
					setAccessToken(token);
					wsLink.close(true);
					return forward(operation);
				});
		}
		if (graphQLErrors) {
			console.log("graphQLErrors", graphQLErrors);
			console.log("operation", operation);
			for (let err of graphQLErrors) {
				switch (err.extensions.code) {
					case "invalid-jwt":
						return fromPromise(getNewToken())
							.filter(async (value) => Boolean(value))
							.flatMap((token) => {
								const oldHeaders =
									operation.getContext().headers;
								operation.setContext({
									headers: {
										...oldHeaders,
										authorization: `Bearer ${token}`,
									},
								});
								setAccessToken(token);
								wsLink.close(true);
								return forward(operation);
							});
					default:
						break;
				}
			}
		}
	}
);

const _link = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === "OperationDefinition" &&
			definition.operation === "subscription"
		);
	},
	wsLink,
	httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
	console.log("operation", operation.getContext());
	// add the authorization to the headers
	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			Authorization: "Bearer " + localStorage.getItem("access_token"),
		},
	}));

	return forward(operation);
});

let lastRequest = null;
const googleAnalyticsLink = new ApolloLink((operation, forward) => {
  const now = new Date();
  if (lastRequest === null || (now - lastRequest) >= 1800000) { // 1800000 milliseconds = 30 minutes
	ReactGA.event({
		category: "Activity",
		action: "KeepingActive",
	});
    lastRequest = now;
  }
  return forward(operation);
});

const link = new ApolloLink.from([googleAnalyticsLink, errorLink, authLink, _link]);

export default link;
