import React, { useEffect, useState, useRef } from "react";
import MsgBoxTable from "../../Components/MsgBoxTable/msg-box-table.component";
import { useToasts } from "react-toast-notifications";
import { Formik } from "formik";
import {
	GET_RESPONSES,
	GET_INBOXES,
	ADD_AUTOMATION,
	DELETE_AUTOMATION,
	EDIT_AUTOMATION,
	GET_USERS,
	DELETE_CHILD_RESPONSES,
	GET_ALL_USERS,
	GET_RESPONSE_KEYWORDS,
} from "./query";
import MsgBoxModal from "../../Components/MsgBoxModal/msgbox-modal.component";
import Input from "../../Components/Input/input.component";
import MsgBoxSelect from "../../Components/MsgBoxSelect/msg-box-select.component";
import TextArea from "../../Components/TextArea/text-area.component";
import client from "../../Apollo";
import { notificationValidationSchema } from "./validation-schema";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
	AddAutomationForm,
	Container,
	TableContainer,
	IphoneContainer,
} from "./styles";
import { GET_ACCOUNT } from "../../Components/APIAccountsSelect/query";
import { getUserId } from "../../helpers/functions";
import IPhone from "../../Components/Iphone/iphone.component";
import BotResponsesList from "./BotResponses/bot-child-response-list.component";
import {
	KEYWORD_ONLY,
	KEYWORD_ANYWHERE,
	KEYWORD_WORD,
	matchTypeNiceName,
	PHRASE,
} from "./helper";
import Modal from "react-modal";
import { $userInboxes } from "../../store";
import Toggle from "react-toggle";
import ReactGA from "react-ga4";
import { TableFiltersContainer } from "../../Styles/global";

Modal.setAppElement("#root");

const initialValues = {
	keyword: "",
	reply: "",
	assignee: "",
	routeTo: "",
	account: "",
	responseType: {
		value: 1,
		label: "Reply",
	},
	matchType: {
		value: KEYWORD_ONLY,
		label: "Keyword Only",
	},
	enabled: true,
	automationReplies: false,
	isDeletable: true,
	feature: {
		label: "None",
		value: false,
	},
	quickReply1: "",
	quickReply2: "",
	quickReply3: "",
	list1: "",
	list2: "",
	list3: "",
	list4: "",
	list5: "",
	list6: "",
	list7: "",
	list8: "",
	list9: "",
	list10: "",
};

const initalModalConfig = {
	type: "add",
	header: "Add Automation",
	description: "Use the form below to add an automation.",
};

const Automations = () => {
	const columns = [
		{
			name: "Keyword",
			selector: "keyword",
		},
		{
			name: "Account",
			selector: "account",
			cell: (row) => row.account.name,
		},
		{
			name: "Assigned To",
			selector: "assign",
			cell: (row) => row.assign.name,
		},
		{
			name: "Routed To",
			selector: "route",
			cell: (row) => row.route.name,
		},
		{
			name: "Keyword Match Type",
			selector: "matchType",
			cell: (row) => {
				if (row.isDeletable) {
					return <div>{matchTypeNiceName[row.matchType]}</div>;
				} else {
					return <div>N/A</div>;
				}
			},
		},
		{
			name: "Enabled",
			selector: "enabled",
			cell: (row) => {
				return (
					<div>
						{row.enabled ? (
							<i className="fas fa-check"></i>
						) : (
							<i className="fas fa-times"></i>
						)}
					</div>
				);
			},
		},
	];

	const userInboxes = useReactiveVar($userInboxes);

	const [addAutomation, { loading: addLoading, data: addData }] =
		useMutation(ADD_AUTOMATION);

	const [deleteAutomation, { loading: deleteLoading, data: deleteData }] =
		useMutation(DELETE_AUTOMATION);

	const [updateAutomation, { loading: updateLoading, data: updateData }] =
		useMutation(EDIT_AUTOMATION);

	const [
		deleteChildAutomation,
		{ loading: deleteChildLoading, data: deleteChildData },
	] = useMutation(DELETE_CHILD_RESPONSES);

	const tableRef = useRef();
	const { addToast } = useToasts();

	const [modalIsOpen, setModalOpen] = useState(false);
	const [users, setUsers] = useState([]);
	const [inboxes, setInboxes] = useState([]);
	const [initialFormValues, setInitialFormValues] = useState(initialValues);
	const [modalConfig, setModalConfig] = useState(initalModalConfig);
	const [selectedAutomation, setSelectedAutomation] = useState([]);
	const [filters, setFilters] = useState(null);
	/**
	 * For api accoutns dropdown menu
	 */
	const [accounts, setAccounts] = useState([]);
	/**
	 * Use for passing the selected row data to the iphone component
	 */
	const [selectedRow, setSelectedRow] = useState([]);
	const [matchTypeVisible, setMatchTypeVisible] = useState(true);
	const [formIsDirty, setFormDirty] = useState(false);
	const [smartphoneIsOpen, setSmartPhoneOpen] = useState(false);
	const [accountValue, setAccountValue] = useState("");
	const [selectedInbox, setSelectedInbox] = useState(null)

	useEffect(() => {
		loadAccountOptions();
	}, []);

	useEffect(() => {
		fetchUsers();
	}, [modalIsOpen, selectedInbox]);

	useEffect(() => {
		loadInboxes();
	}, [accountValue]);

	const formatData = (data) => {
		const botResponses = data.msgbox_BotResponse.map((response) => {
			// Default to Not assigned to a department or user
			let assignee = {
				name: "N/A",
				data: null,
			};
			if (response.AssignTo !== null) {
				// Assigned to a user
				if (response.userByAssignto) {
					assignee = {
						name:
							response.userByAssignto.FirstName +
							" " +
							response.userByAssignto.LastName,

						// The user id of the assignee
						data: response.AssignTo,
						type: "user",
					};
				}
			} else if (
				response.AssignToDepartment !== null &&
				response.Department
			) {
				if (response.Department) {
					assignee = {
						name: response.Department.Name,
						data: response.Department.DepartmentId,
						type: "department",
					};
					// Assigned to department
				}
			}
			return {
				id: response.BotResponseId,
				keyword: response.Keyword,
				reply: response.Reply,
				isDeletable: response.IsDeletable,
				account: {
					name: response.APIAccount.Name,
					value: response.APIAccount.APIAccountId,
				},
				// Include the folder id so modal has the correct info
				route: response.RouteTo
					? {
							name:
								response.Folder.Inbox.Name +
								" " +
								`(${response.Folder.Name})`,
							data: response.Folder.FolderId,
					  }
					: {
							name: "N/A",
							data: null,
					  },
				// Includes the assignee data so edit modal will have access.
				assign: assignee,
				requiresResponse: response.RequiresResponse,
				captureEmailAddress: response.CaptureEmailAddress,
				matchType: response.BotResponseType,
				enabled: response.IsEnabled,
				ListMetadata: response.ListMetadata,
				ReplyMetadata: response.ReplyMetadata,
			};
		});
		return [
			botResponses,
			data.msgbox_BotResponse_aggregate.aggregate.count,
		];
	};

	const fetchUsers = async () => {
		const response = await client.query({
			query: GET_USERS,
			fetchPolicy: 'no-cache'
		});
		const users = await client.query({
			query: GET_ALL_USERS,
			fetchPolicy: 'no-cache'
		});
		const options = [];
		response.data.msgbox_Organisation[0].Departments.filter((d) => {
			if(d.DepartmentInboxes.length == 0) return true
			let arr = d.DepartmentInboxes.filter((i) => {
				return i.InboxId == selectedInbox
			})
			return arr.length > 0 ? true: false
		}).forEach(
			(department) => {
				if (department.UserDepartments.length > 0) {
					// Add the users to the dropdown
					const group = {
						label: department.Name,
						options: department.UserDepartments.map((user) => ({
							label:
								user.User.FirstName + " " + user.User.LastName,
							value: {
								type: "user",
								value: user.User.UserId,
							},
						})),
					};
					// Add the department
					group.options.push({
						label: `Assign to team (${department.Name})`,
						value: {
							type: "department",
							value: department.DepartmentId,
						},
					});
					options.push(group);
				}
			}
		);
		const usersNotInATeam = users.data.msgbox_User
			.filter((user) => user.UserDepartments.length < 1)
			.map((user) => ({
				value: { type: "user", value: user.UserId },
				label: `${user.FirstName} ${user.LastName}`,
			}));
		options.push({
			label: "Users not in a team",
			options: usersNotInATeam,
		});
		setUsers(options);
	};

	const loadInboxes = async () => {
		const response = await client.query({
			query: GET_INBOXES,
		});
		const options = [];
		response.data.msgbox_Inbox.forEach((inbox) => {
			if (inbox.APIAccount.Name === accountValue) {
				inbox.Folders.forEach((folder) => {
					options.push({
						label: inbox.Name + " " + `(${folder.Name})`,
						value: {
							inbox: {
								name: inbox.Name,
								id: inbox.InboxId,
							},
							folderId: folder.FolderId,
						},
					});
				});
			}
		});

		setInboxes(options);
		//console.log("options", options);
	};

	const loadAccountOptions = async () => {
		try {
			const response = await client.query({
				query: GET_ACCOUNT,
				variables: {
					userId: getUserId(),
				},
			});
			const options = response.data.msgbox_User[0].UserInboxes.filter((item) => item.Inbox).map(
				(inbox) => ({
					label: inbox.Inbox.APIAccount.Name,
					value: inbox.Inbox.APIAccount.APIAccountId,
					inbox: inbox.Inbox.InboxId
				})
			);

			if (options.length === 1) {
				setInitialFormValues({
					...initialFormValues,
					account: options[0],
				});
				setSelectedInbox(options[0].inbox)
			}
			setAccounts(options);
			setFilters({
				...filters,
				apiAccounts: options.map((x) => x.value),
			});
		} catch (error) {
			addToast("An error occured", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	const handleAddAutomation = async (values, formActions) => {
		let ReplyMetaData = null;
		let ListMetaData = null;
		if (values.feature.value === "Quick Reply") {
			let temp = [];
			if (values.quickReply1 !== "")
				temp.push({
					postbackText: "dev-defined-postback1",
					type: "text",
					title: values.quickReply1,
				});
			if (values.quickReply2 !== "")
				temp.push({
					postbackText: "dev-defined-postback2",
					type: "text",
					title: values.quickReply2,
				});
			if (values.quickReply3 !== "")
				temp.push({
					postbackText: "dev-defined-postback3",
					type: "text",
					title: values.quickReply3,
				});
			if (temp !== []) ReplyMetaData = JSON.stringify(temp);
		}
		if (values.feature.value === "List") {
			let temp = [];
			if (values.list1 !== "")
				temp.push({
					postbackText: `${values.list1} postback payload`,
					type: "text",
					title: values.list1,
				});
			if (values.list2 !== "")
				temp.push({
					postbackText: `${values.list2} postback payload`,
					type: "text",
					title: values.list2,
				});
			if (values.list3 !== "")
				temp.push({
					postbackText: `${values.list3} postback payload`,
					type: "text",
					title: values.list3,
				});
			if (values.list4 !== "")
				temp.push({
					postbackText: `${values.list4} postback payload`,
					type: "text",
					title: values.list4,
				});
			if (values.list5 !== "")
				temp.push({
					postbackText: `${values.list5} postback payload`,
					type: "text",
					title: values.list5,
				});
			if (values.list6 !== "")
				temp.push({
					postbackText: `${values.list6} postback payload`,
					type: "text",
					title: values.list6,
				});
			if (values.list7 !== "")
				temp.push({
					postbackText: `${values.list7} postback payload`,
					type: "text",
					title: values.list7,
				});
			if (values.list8 !== "")
				temp.push({
					postbackText: `${values.list8} postback payload`,
					type: "text",
					title: values.list8,
				});
			if (values.list9 !== "")
				temp.push({
					postbackText: `${values.list9} postback payload`,
					type: "text",
					title: values.list9,
				});
			if (values.list10 !== "")
				temp.push({
					postbackText: `${values.list10} postback payload`,
					type: "text",
					title: values.list10,
				});
			if (temp !== [])
				ListMetaData = JSON.stringify([
					{ title: "Select An Option", options: temp },
				]);
		}
		await addAutomation({
			variables: {
				ListMetaData,
				ReplyMetaData,
				reply: values.reply,
				keyword: values.keyword,
				assignTo: values.assignee
					? values.assignee.value.type === "user"
						? values.assignee.value.value
						: null
					: null,
				routeTo: values.routeTo ? values.routeTo.value.folderId : null,
				account: values.account.value,
				assignToDepartment: values.assignee
					? values.assignee.value.type === "department"
						? values.assignee.value.value
						: null
					: null,
				matchType: values.matchType.value,
				isEnabled: values.enabled,
			},
		});
		if (values.automationReplies) {
			let accountsArray = accounts.map((item) => item.value);
			let allReplies = await client.query({
				query: GET_RESPONSE_KEYWORDS,
				variables: {
					apiAccounts: accountsArray,
				},
			});
			const replyLookup = {};
			for (
				let i = 0;
				i < allReplies.data.msgbox_BotResponse.length;
				i++
			) {
				const reply = allReplies.data.msgbox_BotResponse[i];
				if (reply.APIAccount && reply.APIAccount.APIAccountId) {
					const key = `${reply.Keyword}-${reply.APIAccount.APIAccountId}`;
					replyLookup[key] = true;
				}
			}
			if (ListMetaData) {
				const options = JSON.parse(ListMetaData)[0].options;
				for (let i = 0; i < options.length; i++) {
					const item = options[i];
					const key = `${item.title}-${values.account.value}`;
					if (!replyLookup[key]) {
						await addAutomation({
							variables: {
								ListMetaData: null,
								ReplyMetaData: null,
								reply: `${item.title} Automated Reply`,
								keyword: item.title,
								assignTo: values.assignee
									? values.assignee.value.type === "user"
										? values.assignee.value.value
										: null
									: null,
								routeTo: values.routeTo
									? values.routeTo.value.folderId
									: null,
								account: values.account.value,
								assignToDepartment: values.assignee
									? values.assignee.value.type ===
									  "department"
										? values.assignee.value.value
										: null
									: null,
								matchType:
									item.title.split(" ").length > 1
										? PHRASE
										: KEYWORD_ONLY,
								isEnabled: true,
							},
						});
					}
				}
			}
			if (ReplyMetaData) {
				const options = JSON.parse(ReplyMetaData);
				for (let i = 0; i < options.length; i++) {
					const item = options[i];
					const key = `${item.title}-${values.account.value}`;
					if (!replyLookup[key]) {
						await addAutomation({
							variables: {
								ListMetaData: null,
								ReplyMetaData: null,
								reply: `${item.title} Automated Reply`,
								keyword: item.title,
								assignTo: values.assignee
									? values.assignee.value.type === "user"
										? values.assignee.value.value
										: null
									: null,
								routeTo: values.routeTo
									? values.routeTo.value.folderId
									: null,
								account: values.account.value,
								assignToDepartment: values.assignee
									? values.assignee.value.type ===
									  "department"
										? values.assignee.value.value
										: null
									: null,
								matchType:
									item.title.split(" ").length > 1
										? PHRASE
										: KEYWORD_ONLY,
								isEnabled: true,
							},
						});
					}
				}
			}
		}
		addToast("Automation Added", {
			appearance: "success",
			autoDismiss: true,
		});
		tableRef.current.refetchData();
		setModalOpen(false);
	};

	const deleteAutomations = async (selectedAutomations) => {
		const deleteableAutomations = selectedAutomations.filter(
			(notification) => notification.isDeletable === true
		);
		if (deleteableAutomations.length === 0) {
			// User has only selected rows that cant be deleted
			alert("The automation(s) you have selected cannot be deleted");
		} else if (
			selectedAutomations.some(
				(automation) => automation.isDeletable === false
			)
		) {
			// Some of the rows that the user has selected "cant" be deleted
			// if user confirms => delete slected rows that can be deleted
			if (
				window.confirm(
					"Some of the automations you have selected cant be deleted. CLicking okay will delete the rows that you can"
				)
			) {
				handleDeleteAutomations(deleteableAutomations);
			}
		} else {
			// All rows selected can be deleted.
			handleDeleteAutomations(deleteableAutomations);
		}
	};

	const handleDeleteAutomations = async (rows) => {
		const promises = [];
		rows.forEach((automation) => {
			promises.push(
				// Deletes parent
				deleteAutomation({
					variables: {
						responseId: automation.id,
					},
				})
			);
			promises.push(
				// Deletes child
				deleteChildAutomation({
					variables: {
						parentId: automation.id,
					},
				})
			);
		});
		await Promise.all(promises);
		addToast("Automation Deleted", {
			appearance: "success",
			autoDismiss: true,
		});
		tableRef.current.refetchData();
	};

	const openEditModal = (selectedAutomation) => {
		const automation = selectedAutomation[0];
		setModalOpen(true);
		setSelectedAutomation(selectedAutomation);
		// determine response type using the selected automation data
		// Assume its a reply by default
		let responseType = {
			label: "Reply",
			value: 1,
		};
		let assignee = "";
		let routeTo = "";

		if (automation.assign.data) {
			responseType = {
				label: "Assign",
				value: 2,
			};
			assignee = {
				label: automation.assign.name,
				value: automation.assign,
			};
		} else if (automation.route.data) {
			responseType = {
				label: "Route",
				value: 3,
			};
			routeTo = {
				label: automation.route.name,
				value: automation.route.data,
			};
		}
		if (automation.isDeletable === false) {
			setMatchTypeVisible(false);
		}
		let parsedList = automation.ListMetadata
			? JSON.parse(automation.ListMetadata)[0].options
			: false;
		let parsedReply = automation.ReplyMetadata
			? JSON.parse(automation.ReplyMetadata)
			: false;
		let obj = {
			...initialFormValues,
			keyword: automation.keyword,
			responseType: responseType,
			routeTo: routeTo,
			assignee: assignee,
			reply: automation.reply,

			account: {
				label: automation.account.name,
				value: automation.account.value,
			},
			matchType: {
				value: automation.matchType,
				label: matchTypeNiceName[automation.matchType],
			},
			enabled: automation.enabled,
			automationReplies: false,
			isDeletable: automation.isDeletable,
			feature: parsedList
				? { label: "List", value: "List" }
				: parsedReply
				? { label: "Quick Reply", value: "Quick Reply" }
				: false,
			quickReply1: parsedReply[0]?.title ? parsedReply[0].title : "",
			quickReply2: parsedReply[1]?.title ? parsedReply[1].title : "",
			quickReply3: parsedReply[2]?.title ? parsedReply[2].title : "",
			list1: parsedList[0]?.title ? parsedList[0].title : "",
			list2: parsedList[1]?.title ? parsedList[1].title : "",
			list3: parsedList[2]?.title ? parsedList[2].title : "",
			list4: parsedList[3]?.title ? parsedList[3].title : "",
			list5: parsedList[4]?.title ? parsedList[4].title : "",
			list6: parsedList[5]?.title ? parsedList[5].title : "",
			list7: parsedList[6]?.title ? parsedList[6].title : "",
			list8: parsedList[7]?.title ? parsedList[7].title : "",
			list9: parsedList[8]?.title ? parsedList[8].title : "",
			list10: parsedList[9]?.title ? parsedList[9].title : "",
		};
		let inbox = accounts.filter((item) => item?.value == automation?.account?.value)
		setSelectedInbox(inbox?.[0]?.inbox)
		setInitialFormValues(obj);
		setModalConfig({
			type: "edit",
			header: "Edit Automation",
			description: "use the form below to edit an automation",
		});
	};

	const closeEditModal = () => {
		setInitialFormValues(initialValues);
		setModalConfig(initalModalConfig);
		setModalOpen(false);
		setMatchTypeVisible(true);
		setFormDirty(false);
	};

	const handleEditAutomation = async (values, formActions) => {
		let ReplyMetaData = null;
		let ListMetaData = null;
		if (values.feature.value === "Quick Reply") {
			let temp = [];
			if (values.quickReply1 !== "")
				temp.push({
					postbackText: "dev-defined-postback1",
					type: "text",
					title: values.quickReply1,
				});
			if (values.quickReply2 !== "")
				temp.push({
					postbackText: "dev-defined-postback2",
					type: "text",
					title: values.quickReply2,
				});
			if (values.quickReply3 !== "")
				temp.push({
					postbackText: "dev-defined-postback3",
					type: "text",
					title: values.quickReply3,
				});
			if (temp !== []) ReplyMetaData = JSON.stringify(temp);
		}
		if (values.feature.value === "List") {
			let temp = [];
			if (values.list1 !== "")
				temp.push({
					postbackText: `${values.list1} postback payload`,
					type: "text",
					title: values.list1,
				});
			if (values.list2 !== "")
				temp.push({
					postbackText: `${values.list2} postback payload`,
					type: "text",
					title: values.list2,
				});
			if (values.list3 !== "")
				temp.push({
					postbackText: `${values.list3} postback payload`,
					type: "text",
					title: values.list3,
				});
			if (values.list4 !== "")
				temp.push({
					postbackText: `${values.list4} postback payload`,
					type: "text",
					title: values.list4,
				});
			if (values.list5 !== "")
				temp.push({
					postbackText: `${values.list5} postback payload`,
					type: "text",
					title: values.list5,
				});
			if (values.list6 !== "")
				temp.push({
					postbackText: `${values.list6} postback payload`,
					type: "text",
					title: values.list6,
				});
			if (values.list7 !== "")
				temp.push({
					postbackText: `${values.list7} postback payload`,
					type: "text",
					title: values.list7,
				});
			if (values.list8 !== "")
				temp.push({
					postbackText: `${values.list8} postback payload`,
					type: "text",
					title: values.list8,
				});
			if (values.list9 !== "")
				temp.push({
					postbackText: `${values.list9} postback payload`,
					type: "text",
					title: values.list9,
				});
			if (values.list10 !== "")
				temp.push({
					postbackText: `${values.list10} postback payload`,
					type: "text",
					title: values.list10,
				});
			if (temp !== [])
				ListMetaData = JSON.stringify([
					{ title: "Select An Option", options: temp },
				]);
		}
		await updateAutomation({
			variables: {
				ListMetaData,
				ReplyMetaData,
				responseId: selectedAutomation[0].id,
				keyword: values.keyword,
				reply: values.reply,
				routeTo: values.routeTo ? values.routeTo.value.folderId : null,
				assignee: values.assignee
					? values.assignee.value.type === "user"
						? values.assignee.value.value
						: null
					: null,
				assignToDepartment: values.assignee
					? values.assignee.value.type === "department"
						? values.assignee.value.value
						: null
					: null,
				matchType: values.matchType.value,
				isEnabled: values.enabled,
			},
		});
		if (values.automationReplies) {
			let accountsArray = accounts.map((item) => item.value);
			let allReplies = await client.query({
				query: GET_RESPONSE_KEYWORDS,
				variables: {
					apiAccounts: accountsArray,
				},
			});
			const replyLookup = {};
			for (
				let i = 0;
				i < allReplies.data.msgbox_BotResponse.length;
				i++
			) {
				const reply = allReplies.data.msgbox_BotResponse[i];
				if (reply.APIAccount && reply.APIAccount.APIAccountId) {
					const key = `${reply.Keyword}-${reply.APIAccount.APIAccountId}`;
					replyLookup[key] = true;
				}
			}
			if (ListMetaData) {
				const options = JSON.parse(ListMetaData)[0].options;
				for (let i = 0; i < options.length; i++) {
					const item = options[i];
					const key = `${item.title}-${values.account.value}`;
					if (replyLookup[key]) {
						continue;
					}
					await addAutomation({
						variables: {
							ListMetaData: null,
							ReplyMetaData: null,
							reply: `${item.title} Automated Reply`,
							keyword: item.title,
							assignTo: values.assignee
								? values.assignee.value.type === "user"
									? values.assignee.value.value
									: null
								: null,
							routeTo: values.routeTo
								? values.routeTo.value.folderId
								: null,
							account: values.account.value,
							assignToDepartment: values.assignee
								? values.assignee.value.type === "department"
									? values.assignee.value.value
									: null
								: null,
							matchType:
								item.title.split(" ").length > 1
									? PHRASE
									: KEYWORD_ONLY,
							isEnabled: true,
						},
					});
				}
			}
			if (ReplyMetaData) {
				const options = JSON.parse(ReplyMetaData);
				for (let i = 0; i < options.length; i++) {
					const item = options[i];
					const key = `${item.title}-${values.account.value}`;
					if (replyLookup[key]) {
						continue;
					}
					await addAutomation({
						variables: {
							ListMetaData: null,
							ReplyMetaData: null,
							reply: `${item.title} Automated Reply`,
							keyword: item.title,
							assignTo: values.assignee
								? values.assignee.value.type === "user"
									? values.assignee.value.value
									: null
								: null,
							routeTo: values.routeTo
								? values.routeTo.value.folderId
								: null,
							account: values.account.value,
							assignToDepartment: values.assignee
								? values.assignee.value.type === "department"
									? values.assignee.value.value
									: null
								: null,
							matchType:
								item.title.split(" ").length > 1
									? PHRASE
									: KEYWORD_ONLY,
							isEnabled: true,
						},
					});
				}
			}
		}
		closeEditModal();
		tableRef.current.refetchData();
		addToast("Automation Updated", {
			appearance: "success",
			autoDismiss: true,
		});
	};

	const submitForm = (values, formActions) => {
		if (modalConfig.type === "edit") {
			handleEditAutomation(values, formActions);
		} else {
			handleAddAutomation(values, formActions);
		}
	};

	const handleSearchChange = (searchTerm) => {
		setFilters({
			searchTerm: "%" + searchTerm + "%",
		});
	};

	const handleAccountFilterChange = (name, option) => {
		let accountId =
			option.value == null ? accounts.map((x) => x.value) : option.value;
		//console.log("accountId:", accountId);
		setFilters({
			...filters,
			apiAccounts: accountId,
		});
	};

	const handleRowClick = (row) => {
		setSmartPhoneOpen(true);
		setSelectedRow(row);
	};

	ReactGA.send({ hitType: "pageview", page: "/automations" });

	return (
		<Container>
			<TableContainer>
				<MsgBoxModal
					aria={{
						labelledby: "heading",
						describedby: "full_description",
					}}
					description={modalConfig.description}
					header={modalConfig.header}
					isOpen={modalIsOpen}
					cancel={closeEditModal}
					onRequestClose={closeEditModal}
					submitId="add_response"
					customModalContentStyles={{
						width: "80%",
					}}
					submitting={addLoading || updateLoading}
					submitButtonDisabled={formIsDirty === false}
				>
					<Formik
						initialValues={initialFormValues}
						onSubmit={(values, formActions) => {
							submitForm(values, formActions);
							console.log("Submit values :", values);
						}}
						validationSchema={notificationValidationSchema}
						validateOnChange={false}
						validateOnBlur={false}
					>
						{({
							handleSubmit,
							handleChange,
							setFieldValue,
							values,
							errors,
						}) => (
							<AddAutomationForm
								id="add_response"
								onSubmit={handleSubmit}
							>
								<div className="column-one">
									<Input
										required
										id="keyword"
										label="Keyword"
										name="keyword"
										type="text"
										value={values.keyword}
										disabled={!values.isDeletable}
										placeholder="keyword"
										onChange={(e) => {
											handleChange(e);
											setFormDirty(true);
										}}
										error={errors.keyword}
										hasTooltip
										toolTipText="Select a keyword that the bot will respond to "
									/>

									<MsgBoxSelect
										isDisabled={!values.isDeletable}
										key={3}
										name="account"
										onChange={(options, action) => {
											setSelectedInbox(options.inbox)
											setAccountValue(options.label);
											setFieldValue("account", options);
											setFieldValue("routeTo", "");
											setFieldValue("assignee", "")
											setFormDirty(true);
										}}
										label="Account"
										options={accounts}
										value={values.account}
										error={errors.account}
										toolTipText="Select an account that you wish this message to be sent from"
										hasTooltip
										required
									/>
									{values?.account && <>
									<MsgBoxSelect
										isDisabled={!values.isDeletable}
										hasTooltip
										toolTipText="Select the type of response"
										options={[
											{
												label: "Reply",
												value: 1,
											},
											{
												label: "Assign",
												value: 2,
											},
											{
												label: "Route",
												value: 3,
											},
										]}
										name="responseType"
										label="Response Type"
										value={values.responseType}
										onChange={(options, actions) => {
											setFormDirty(true);
											setFieldValue(
												"responseType",
												options
											);
											if (options.value === 1) {
												// User has selected reply => clear out any previous state associated with assigning and routing
												setFieldValue("assignee", "");
												setFieldValue("routeTo", "");
											}
										}}
										required
									/>
									{values?.responseType?.value == 2 && (
										<MsgBoxSelect
											isDisabled={!values.isDeletable}
											name="assignee"
											options={users}
											hasTooltip
											toolTipText="Assign a user to you wish to asign this response to"
											value={values.assignee}
											label="Assignee"
											required
											onChange={(options, action) => {
												setFieldValue(
													"assignee",
													options
												);
												setFieldValue("routeTo", "");
												setFormDirty(true);
											}}
											error={errors.assignee}
										/>
									)}
									{values?.responseType?.value == 3 && (
										<MsgBoxSelect
											isDisabled={!values.isDeletable}
											options={inboxes}
											hasTooltip
											toolTipText="Choose an inbox to route this response to"
											name="routeTo"
											value={values.routeTo}
											label="Choose where to route this message"
											required
											onChange={(options, action) => {
												setFieldValue(
													"routeTo",
													options
												);
												setFieldValue("assignee", "");
												setFormDirty(true);
											}}
											error={errors.routeTo}
										/>
									)}
									</>
									}
									{matchTypeVisible && (
										<MsgBoxSelect
											isDisabled={!values.isDeletable}
											key={4}
											name="matchType"
											onChange={(options, action) => {
												setFieldValue(
													"matchType",
													options
												);
												setFormDirty(true);
											}}
											label="Match Type"
											options={[
												{
													value: KEYWORD_ONLY,
													label: "Keyword Only",
												},
												{
													value: KEYWORD_ANYWHERE,
													label: "Keyword Anywhere",
												},
												{
													value: KEYWORD_WORD,
													label: "Keyword as word",
												},
												{
													value: PHRASE,
													label: "Phrase",
												},
											]}
											value={values.matchType}
											toolTipText="Select the way you would like the keyword to be parsed"
											hasTooltip
											required
										/>
									)}
									<MsgBoxSelect
										key={6}
										label="Feature"
										name="feature"
										onChange={(options, action) => {
											setFieldValue("feature", options);
											setFormDirty(true);
										}}
										options={[
											{
												label: "None",
												value: false,
											},
											{
												label: "Quick Reply",
												value: "Quick Reply",
											},
											{
												label: "List",
												value: "List",
											},
										]}
										value={values.feature}
										error={errors.feature}
										component={MsgBoxSelect}
										toolTipText="Add a message feature"
										hasTooltip
										required
									/>
								</div>
								<div className="column-two">
									<TextArea
										value={values.reply}
										name="reply"
										required={
											values?.responseType?.value === 1
										}
										hasTooltip
										toolTipText="add reply here"
										onChange={(e) => {
											handleChange(e);
											setFormDirty(true);
										}}
										label="Reply"
										error={errors.reply}
										style={{ height: 185 }}
									/>
									<div>
										<div>
											<label>
												Automation
												{values.enabled
													? " enabled"
													: " disabled"}
											</label>
										</div>
										<Toggle
											id="toggled"
											onChange={(event) => {
												setFormDirty(true);
												setFieldValue(
													"enabled",
													event.target.checked
												);
											}}
											name="enabled"
											checked={values.enabled}
										/>
									</div>
									<div>
										<label>
											Create Automation Replies
											{values.automationReplies
												? " enabled"
												: " disabled"}
										</label>
									</div>
									<Toggle
										id="automationReplies"
										onChange={(event) => {
											setFormDirty(true);
											setFieldValue(
												"automationReplies",
												event.target.checked
											);
										}}
										name="automationReplies"
										checked={values.automationReplies}
									/>
								</div>
								{values.feature.value === "Quick Reply" && (
									<div className="column-two">
										<TextArea
											name="quickReply1"
											label="Button 1 Text"
											placeholder="Text For Button 1"
											value={values.quickReply1}
											onChange={(event) => {
												if (
													event.target.value.length >
													20
												)
													return;
												handleChange(event);
												setFormDirty(true);
											}}
										/>
										<TextArea
											name="quickReply2"
											label="Button 2 Text"
											placeholder="Text For Button 2"
											value={values.quickReply2}
											onChange={(event) => {
												if (
													event.target.value.length >
													20
												)
													return;
												handleChange(event);
												setFormDirty(true);
											}}
										/>
										<TextArea
											name="quickReply3"
											label="Button 3 Text"
											placeholder="Text For Button 3"
											value={values.quickReply3}
											onChange={(event) => {
												if (
													event.target.value.length >
													20
												)
													return;
												handleChange(event);
												setFormDirty(true);
											}}
										/>
									</div>
								)}
								{values.feature.value === "List" && (
									<>
										<div className="column-two">
											<TextArea
												name="list1"
												label="List Item 1"
												placeholder="Text For Item 1"
												value={values.list1}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
											<TextArea
												name="list2"
												label="List Item 2"
												placeholder="Text For Item 2"
												value={values.list2}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
											<TextArea
												name="list3"
												label="List Item 3"
												placeholder="Text For Item 3"
												value={values.list3}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
											<TextArea
												name="list4"
												label="List Item 4"
												placeholder="Text For Item 4"
												value={values.list4}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
											<TextArea
												name="list5"
												label="List Item 5"
												placeholder="Text For Item 5"
												value={values.list5}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
										</div>
										<div className="column-two">
											<TextArea
												name="list6"
												label="List Item 6"
												placeholder="Text For Item 6"
												value={values.list6}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
											<TextArea
												name="list7"
												label="List Item 7"
												placeholder="Text For Item 7"
												value={values.list7}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
											<TextArea
												name="list8"
												label="List Item 8"
												placeholder="Text For Item 8"
												value={values.list8}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
											<TextArea
												name="list9"
												label="List Item 9"
												placeholder="Text For Item 9"
												value={values.list9}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
											<TextArea
												name="list10"
												label="List Item 10"
												placeholder="Text For Item 10"
												value={values.list10}
												onChange={(event) => {
													if (
														event.target.value
															.length > 24
													)
														return;
													handleChange(event);
													setFormDirty(true);
												}}
											/>
										</div>
									</>
								)}
							</AddAutomationForm>
						)}
					</Formik>
				</MsgBoxModal>
				<MsgBoxTable
					rowClick={handleRowClick}
					handleSearchChange={handleSearchChange}
					filters={filters}
					searchPlaceHolder="Search keyword..."
					ref={tableRef}
					openModal={() => {
						setModalOpen(true);
					}}
					formatData={formatData}
					columns={columns}
					fetchRows={GET_RESPONSES}
					defaultOrderBy={{
						field: "Keyword",
						sortDirection: "asc",
					}}
					subHeader
					subHeaderAlign={"left"}
					subHeaderComponent={[
						<TableFiltersContainer>
							<MsgBoxSelect
								key={2}
								onChange={(options) => {
									handleAccountFilterChange(
										"account",
										options
									);
								}}
								name="account"
								label="Account"
								table
								options={[
									...accounts,
									{ label: "Any", value: null },
								]}
							/>
						</TableFiltersContainer>,
						<p>
							Click on a row in the table to set up an automation
							flow
						</p>,
					]}
					defaultLimit={10}
					hasEdit
					selectableRows
					title="Automations"
					deleteRows={deleteAutomations}
					editRows={openEditModal}
					expandOnRowClicked
					pagination
					paginationServer
					sortServer
					pointerOnHover
					selectedRowsChange={(values) =>
						setAccountValue(values[0]?.account?.name)
					}
				/>
			</TableContainer>
			<IphoneContainer>
				<IPhone>
					<BotResponsesList parentResponse={selectedRow} />
				</IPhone>
			</IphoneContainer>
		</Container>
	);
};

export default Automations;
