import React, { useContext, useRef, useState } from "react";
import Button from "../../../Components/Button/button.component";
import { UserContext } from "../../../Providers/user-provider";
import Papa from "papaparse";
import ImportModal from "./import-modal/import-modal";
import { COLUMNS_PATTERN, EMAIL_REGEX, PHONE_REGEX } from "./validation";
import {read, utils} from "xlsx"; // Import the xlsx library

const BulkContactImport = () => {
	const [importModalOpen, setImportModalOpen] = useState(false);

	const open = () => setImportModalOpen(true);

	const userContext = useContext(UserContext);
	const fileInputRef = useRef(null);
	const [fileError, setFileError] = useState(null);
	const [dataErrors, setDataErrors] = useState([]);
	const [parsedData, setParsedData] = useState([]);

	const clearFile = () => {
		setParsedData([]);
		setFileError(null);
		setDataErrors([]);
	};
	const columnsMatch = (columns, columnsPattern) => {
		const filteredColumns = columns.filter(
			(c) => c !== null && c !== undefined
		);
		if (filteredColumns.length < columnsPattern.length) {
			setFileError("Too few columns");
			return false;
		}

		let result = true;

		columnsPattern.forEach((column) => {
			const foundColIndex = filteredColumns.findIndex(
				(c) => c === column
			);

			if (foundColIndex === -1) {
				setDataErrors((prev) => [
					...prev,
					{
						rowNumber: null,
						error: `Missing column: ${column}`,
					},
				]);
				result = false;
			}

			filteredColumns[foundColIndex] = column;
		});

		return result;
	};

	const validData = (data) => {
		data.map(x => {
			x.mobile = x.mobile.toString().trim();
			if (!x.mobile.startsWith("+")) {
				x.mobile = "+" + x.mobile;
			}
			return x;
		}).forEach((row, index) => {
			const isValidNumber = PHONE_REGEX.test(
				row.mobile.trim().replace(" ", "")
			);
			if (!isValidNumber) {
				setDataErrors((prev) => [
					...prev,
					{
						rowNumber: index,
						error: "Invalid phone number format",
					},
				]);
			}
			if (row.email && row.email.length > 0) {
				const isValidEmail = EMAIL_REGEX.test(row.email);
				if (!isValidEmail) {
					setDataErrors((prev) => [
						...prev,
						{
							rowNumber: index,
							error: "Invalid email format",
						},
					]);
				}
			}
		});
	};

	function bulkUploadType(type){
			 if(["text/csv"].includes(type)) return 'csv'
			 if(["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(type)) return 'xlsx'
			 return false
	}

	function formtatXMLS(data){
		let arr = []
		for(let i = 1; i < data.length; i++){
			let obj = {}
			for(let x = 0; x < data[0].length; x++){
				let value = data[i][x] || ''
				obj[data[0][x]] = value.toString()
			}
			arr.push(obj)
		}
		return arr
	}

	const handleUpload = async (e) => {
		setFileError(null);
		setDataErrors([]);
		const file1 = e.target.files && e.target.files[0];
		const type = bulkUploadType(file1.type)
		if (file1) {
			if (!type) {
				setFileError("Invalid file type. Expected .csv or .xlsx");
				open();
			} else if (type === 'csv') {
				Papa.parse(file1, {
					header: true,
					skipEmptyLines: true,
					complete: function (results) {
						const rowsArray = [];
						const valuesArray = [];
						results.data.map((d) => {
							rowsArray.push(Object.keys(d));
							valuesArray.push(Object.values(d));
						});
						setParsedData(results.data);
						const isValid = columnsMatch(
							rowsArray[0],
							COLUMNS_PATTERN
						);
						if (isValid) {
							validData(results.data);
						}
						open();
					},
				});
			} else if (type === 'xlsx') {
				const reader = new FileReader();
				reader.onload = function(e) {
					let temp = new Uint8Array(e.target.result);
					let workbook = read(temp, { type: 'array' });
					let sheetName = workbook.SheetNames[0];
					let worksheet = workbook.Sheets[sheetName];
					const data = utils.sheet_to_json(worksheet, { header: 1, raw: false })
					const formatted = formtatXMLS(data)
					setParsedData(formatted)
					const isValid = columnsMatch(
						data[0],
						COLUMNS_PATTERN
					);
					if (isValid) {
						validData(formatted)
					}
					open();
				};
				reader.readAsArrayBuffer(file1);
			}
		}
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	return (
		<>
			{userContext.role === "orgadmin" && (
				<>
					<input
						accept=".csv, .xlsx"
						type="file"
						style={{ display: "none" }}
						onChange={handleUpload}
						ref={fileInputRef}
					/>
					<Button
						primary
						buttonName="Bulk Contact Import"
						onClick={() => {
							if (fileInputRef.current) {
								fileInputRef.current.click();
							}
						}}
					/>
				</>
			)}
			<ImportModal
				importModalOpen={importModalOpen}
				setImportModalOpen={setImportModalOpen}
				fileError={fileError}
				dataErrors={dataErrors}
				parsedData={parsedData}
				clearFile={clearFile}
			/>
		</>
	);
};

export default BulkContactImport;
