import React, {
	useEffect,
	useState,
	useImperativeHandle,
	forwardRef,
} from "react";
import SearchBar from "../SearchBar/search-bar.component";
import Button from "../../Components/Button/button.component";
import DataTable from "react-data-table-component";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import { Container, ContextActionButton } from "./styles";
import Pagination from "../Pagination/paginate";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import useDefaultTableStyles from "./use-default-table-styles";
import BulkContactImport from "../../Pages/Contacts/bulk-contact-import/bulk-contact-import";

const MsgBoxTable = forwardRef(
	(
		{
			rowClick,
			hasSearch,
			hasExport,
			hasEdit,
			editableValidation = () => true,
			editRows,
			fetchRows,
			deleteRows,
			defaultOrderBy,
			defaultLimit,
			formatData,
			openModalButtonName,
			openModalButtonTooltip,
			filterIgnore,
			rowsUpdated,
			openModal,
			exportDownloadFileName,
			filters,
			hasDelete,
			customContextActions,
			selectedRowsChange,
			handleSearchChange,
			searchPlaceHolder,
			queryVariables,
			showBulkContactImport = false,
			hasAddButton,
			...reactDataTableProps
		},
		ref
	) => {
		const [tableRows, setTableRows] = useState([]);
		const [visibleTableRows, setVisibleTableRows] = useState([]);
		const [selectedRows, setSelectedRows] = useState([]);
		const [totalRows, setTotalRows] = useState(0);
		const [limit, setLimit] = useState(defaultLimit);
		const [offset, setOffset] = useState(0);
		const [orderBy, setOrderBy] = useState(defaultOrderBy);
		const [clearRows, setClearRows] = useState(false);
		

		const { loading, error, data, refetch } = useQuery(fetchRows, {
			fetchPolicy: "network-only",
			variables: {
				offset: 0,
				limit: defaultLimit,
				order_by: {
					[orderBy.field]: orderBy.sortDirection,
				},
				...queryVariables,
				...filters,
			},
		});

		const theme = useTheme();
		const defaultTableStyles = useDefaultTableStyles();

		useEffect(() => {
			if (data) {
				const [tableRows, totalRows] = formatData(data);
				setTableRows(tableRows);
				setVisibleTableRows(tableRows);
				setTotalRows(totalRows);
				setClearRows(!clearRows);
			}
		}, [data]);

		useEffect(() => {
			refetchData();
		}, [orderBy, limit, offset, filters]);

		const refetchData = () => {
			refetch({
				limit,
				offset,
				order_by: {
					[orderBy.field]: orderBy.sortDirection,
				},
				...filters,
				...queryVariables,
			});
		};

		useImperativeHandle(ref, () => ({
			refetchData: refetchData,
		}));

		const onSelectedTableRowChange = (rows) => {
			setSelectedRows(rows.selectedRows);
			selectedRowsChange(rows.selectedRows);
		};
		
		const handlePageChange = (page) => {
			const offset = (page - 1) * limit;
			setOffset(offset);
		};

		const handlePageLimitChange = (limit) => {
			setLimit(limit);
		};

		const handleSortChange = (column, sortDirection) => {
			setOrderBy({
				field: column.sortField,
				sortDirection,
			});
		};

		const handleRowClick = (row) => {
			rowClick(row);
		};

		

		const exportRows = (data) => {
			try {
				const link = document.createElement("a");
				let csv = convertArrayOfObjectsToCSV(data);
				if (csv == null) return;
				const filename = `${exportDownloadFileName}.csv`;
				if (!csv.match(/^data:text\/csv/i)) {
					csv = `data:text/csv;charset=utf-8,${csv}`;
				}
				link.setAttribute("href", encodeURI(csv));
				link.setAttribute("download", filename);
				link.click();
				setClearRows(!clearRows);
			} catch (error) {}
		};

		const convertArrayOfObjectsToCSV = (array) => {
			// Will need to fix this for other pages that dont have tags
			const newArray = array.map((item) => {
				return {
					...item,
					tags: item.tags.join("; "),
				};
			});
			let result;
			const columnDelimiter = ",";
			const lineDelimiter = "\n";
			const keys = Object.keys(visibleTableRows[0]).filter(
				(item) => item !== "actions" && item !== "id"
			);
			result = "";
			result += keys.join(columnDelimiter);
			result += lineDelimiter;
			newArray.forEach((item) => {
				let ctr = 0;
				keys.forEach((key) => {
					if (key === "actions") return;
					if (ctr > 0) result += columnDelimiter;
					result += item[key];
					ctr++;
				});
				result += lineDelimiter;
			});
			return result;
		};

		if (error) {
			console.log("error", error);
		}

		return (
			<Container>
				<DataTable
					customStyles={defaultTableStyles}
					actions={[
						hasSearch === true && (
							<SearchBar
								placeholder={searchPlaceHolder}
								onSearchChange={handleSearchChange}
							/>
						),
						<>{showBulkContactImport && <BulkContactImport />}</>,
						hasAddButton && (
							<Button
								primary
								title={openModalButtonTooltip}
								buttonName={openModalButtonName}
								onClick={() => {
									openModal();
								}}
							/>
						),
					]}
					contextActions={[
						...customContextActions,
						hasDelete === true && (
							<ContextActionButton
								title="Delete selected rows"
								style={{ color: theme.colours.danger }}
								onClick={() => {
									if (
										window.confirm(
											"Are you sure? \n\nThis will permanently delete rows from this table!\n"
										)
									) {
										deleteRows(selectedRows);
									}
								}}
								icon="fas fa-trash-alt"
							/>
						),
						hasExport === true && (
							<ContextActionButton
								primary
								onClick={() => {
									exportRows(selectedRows);
								}}
								title="Export Rows"
								icon="fas fa-cloud-download-alt"
							/>
						),
						(hasEdit === true && selectedRows.length === 1 && editableValidation(selectedRows)) && (
							<ContextActionButton
								
								title="Edit selected rows"
								style={{ color: theme.colours.primary }}
								onClick={() => {
									editRows(selectedRows);
								}}
								icon="fas fa-edit"
							/>
						),
					]}
					paginationTotalRows={totalRows}
					data={visibleTableRows}					
					onSelectedRowsChange={onSelectedTableRowChange}
					onChangeRowsPerPage={handlePageLimitChange}
					onChangePage={handlePageChange}
					onSort={handleSortChange}
					progressPending={loading}
					progressComponent={<LoadingSpinner />}
					clearSelectedRows={clearRows}
					// overflowY={true}
					paginationComponent={Pagination}
					highlightOnHover
					dense
					onRowClicked={handleRowClick}
					persistTableHead
					{...reactDataTableProps}
				/>
			</Container>
		);
	}
);

MsgBoxTable.propTypes = {
	/**
	 * Wheather or not to enable search - defaults to true
	 */
	hasSearch: PropTypes.bool,
	/**
	 * Wheather or not to enable exporting rows - defaults to false
	 */
	hasExport: PropTypes.bool,
	/**
	 * GraphQl query that will be used to fetch row data
	 */
	fetchRows: PropTypes.object,
	/**
	 * Function that will be called on delete icon click. The selected rows will be passed as an arg
	 */
	deleteRows: PropTypes.func,
	defaultOrderBy: PropTypes.shape({
		field: PropTypes.string,
		direction: PropTypes.string,
	}),
	defaultLimit: PropTypes.number,
	/**
	 * Function that will be used to format the data for react data table
	 */
	formatData: PropTypes.func,
	openModalButtonName: PropTypes.string,
	openModalButtonTooltip: PropTypes.string,
	/**
	 * Values that should be ignored by the search bar - defaults to ["id", "actions", data]
	 * search can only search for string values - any non string thigns will break
	 */
	filterIgnore: PropTypes.arrayOf(PropTypes.string),
	/**
	 * When this value changes, new rows will be fetched using the provided fetch rows function
	 */
	openModal: PropTypes.func,
	exportDownloadFileName: PropTypes.string,
	/**
	 * Function called when a row is clicked. Selected row is passed as an argument
	 */
	rowClick: PropTypes.func,
	hasEdit: PropTypes.bool,
	hasAddButton: PropTypes.bool,
	/**
	 * Functuon that will be called to edit rows. selected row passed as arg
	 */
	editRows: PropTypes.func,
	hasDelete: PropTypes.bool,
	customContextActions: PropTypes.array,
	selectedRowsChange: PropTypes.func,
	searchPlaceHolder: PropTypes.string,
	queryVariables: PropTypes.object,
};

MsgBoxTable.defaultProps = {
	hasSearch: true,
	hasExport: false,
	hasEdit: false,
	hasDelete: true,
	hasAddButton: true,
	defaultLimit: 10,
	openModalButtonName: "Add",
	openModalButtonTooltip: "Add row",
	exportDownloadFileName: "data",
	customContextActions: [],
	filterIgnore: [],
	rowClick: () => {},
	selectedRowsChange: () => {},
	searchPlaceHolder: "Search here...",
	queryVariables: {},
};

export default MsgBoxTable;
