import { gql } from "@apollo/client";

export const GET_ALL_USER_INBOXES = gql`
	query getAllUserInboxes($userId: Int!) @cached(ttl: 300) {
		msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			Inbox {
				InboxId
				IsPrivate
				IsTeam
				APIAccountId
				Name
				Folders(
					order_by: { Order: asc }
					where: { IsInbox: { _eq: true } }
				) {
					Name
					FolderId
					ParentFolderId
					IsSytemFolder
					IsArchive
					Conversations(
						where: {
							IsRead: { _eq: false }
							IsHidden: { _eq: false }
							Contact: { IsDeleted: { _eq: false } }
						}
					) {
						ConversationId
						AssignedTo
						AssignedToDepartment
					}
				}
			}
		}
	}
`;

export const SUBSCRIBE_TO_MORE_INBOXES = gql`
	subscription subscribeAllUserInboxes($userId: Int!) {
		msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			Inbox {
				InboxId
				IsPrivate
				IsTeam
				APIAccountId
				Name
				Folders(
					order_by: { Order: asc }
					where: { IsInbox: { _eq: true } }
				) {
					Name
					FolderId
					ParentFolderId
					IsSytemFolder
					IsArchive
					Conversations(
						where: {
							IsRead: { _eq: false }
							Contact: { IsDeleted: { _eq: false } }
						}
					) {
						ConversationId
					}
				}
			}
		}
	}
`;
