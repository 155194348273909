import { Formik, Field } from "formik";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import Input from "../Input/input.component";
import { ADD_USER, GET_USERS, GET_DEPARTMENTS, GET_ROLES } from "./query";
import { useMutation, useQuery } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import { addUserValidationSchema, validateEmail } from "./validation-schema";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useState, useEffect } from "react";
import UserInboxSelect from "../UserInboxSelect/user-inbox-select.component";
import { Form, FormColumn } from "./styles";

const AddUser = ({ isOpen, close }) => {
	const [addUser, { loading, error }] = useMutation(ADD_USER);
	const [dirty, setDirty] = useState(false);
	const [userRoleId, setUserRoleId] = useState(0);

	const {
		data: departmentData,
		error: departmentError,
		laoding: departmentLoading,
	} = useQuery(GET_DEPARTMENTS);

	const {
		data: userRoles,
		error: roleError,
		loading: roleLoading,
	} = useQuery(GET_ROLES);

	useEffect(() => {
		if (userRoles && userRoles.msgbox_Role) {
			console.log("userRoles:", userRoles.msgbox_Role);
			let userRole = userRoles.msgbox_Role.filter(
				(role) => role.Name == "User"
			);
			console.log("userRole:", userRole);
			if (userRole && userRole[0]) {
				let userRoleId = userRole[0].RoleId;
				setUserRoleId(userRoleId);
				console.log("user roleid is:", userRoleId);
			}
		}
	}, [userRoles]);

	const { addToast } = useToasts();

	const submit = ({
		firstName,
		lastName,
		email,
		departments,
		role,
		inboxes,
	}) => {
		let data = null;
		if (departments) {
			data = departments.map((department) => ({
				DepartmentId: department.value,
			}));
		}
		addUser({
			variables: {
				firstName,
				lastName,
				email,
				departments: data ? { data } : null,
				role: { data: { RoleId: role.value } },
				inboxes: {
					data: inboxes.map((inbox) => ({ InboxId: inbox.value })),
				},
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_User(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.insert_msgbox_User,
								query: GET_USERS,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
				addToast("Successfully added user", {
					appearance: "success",
					autoDismiss: true,
				});
				close();
			},
		});
	};

	const closeModal = () => {
		close();
		setDirty(false);
	};

	const onInputChange = (event, fn) => {
		setDirty(true);
		fn(event);
	};

	if (roleLoading) return null;

	if (roleError) {
		console.log("role err", roleError);
	}

	return (
		<MsgBoxModal
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			isOpen={isOpen}
			cancel={closeModal}
			header="Add User"
			description="Use the form below to add a user"
			onRequestClose={closeModal}
			submitting={loading}
			submit={submit}
			submitId="add-user"
			submitButtonDisabled={dirty === false}
		>
			<Formik
				initialValues={{
					firstName: "",
					lastName: "",
					email: "",
					departments: "",
					role: { label: "User", value: userRoleId },
					inboxes: "",
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={addUserValidationSchema}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({
					handleSubmit,
					values,
					errors,
					handleChange,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} id="add-user">
						<FormColumn>
							<Input
								placeholder="First Name"
								required
								name="firstName"
								label="First Name"
								onChange={(event) =>
									onInputChange(event, handleChange)
								}
								error={errors.firstName}
								value={values.firstName}
							/>
							<Input
								placeholder="last Name"
								required
								name="lastName"
								label="Last Name"
								onChange={(event) =>
									onInputChange(event, handleChange)
								}
								error={errors.lastName}
								value={values.lastName}
							/>
							<Field
								placeholder="Email"
								required
								name="email"
								label="Email"
								onChange={(event) => {
									setFieldValue("email", event.target.value);
									setDirty(true);
								}}
								validate={validateEmail}
								error={errors.email}
								value={values.email}
								component={Input}
							/>
						</FormColumn>
						<FormColumn>
							<MsgBoxSelect
								key={2}
								placeholder="Select a team"
								name="departments"
								options={
									(departmentData && departmentData.msgbox_Department.length > 0)
										? [
												...departmentData.msgbox_Department,
										  ].map((department) => ({
												label: department.Name,
												value: department.DepartmentId,
										  }))
										: null
								}
								onChange={(options, action) => {
									setFieldValue("departments", options);
									setDirty(true);
								}}
								label="Team"
								error={errors.departments}
								value={values.departments}
								isMulti
								hasTooltip
								toolTipText="Select the teams you would like this user to be part of. A user can be in multiple teams"
							/>
							<MsgBoxSelect
								key={3}
								placeholder="Select a role"
								name="role"
								options={userRoles.msgbox_Role.map((role) => ({
									label: role.Description,
									value: role.RoleId,
								}))}
								onChange={(options, action) => {
									setFieldValue("role", options);
									setDirty(true);
								}}
								label="Role"
								error={errors.role}
								value={values.role}
								hasTooltip
								toolTipText="Select this users role. An admin has the ability to change settings"
							/>
							<UserInboxSelect
								name="inboxes"
								placeholder="Select an inbox"
								key={4}
								handleChange={(options, action) => {
									setFieldValue("inboxes", options);
									setDirty(true);
								}}
								label="inboxes"
								hasTooltip
								toolTipText="Select the inboxes you would like this user to have access to"
								error={errors.inboxes}
								value={values.inboxes}
							/>
						</FormColumn>
					</Form>
				)}
			</Formik>
		</MsgBoxModal>
	);
};

export default AddUser;
