import { makeAutoObservable, runInAction, reaction } from "mobx"
import client from "../Apollo";
import { RootStore } from "./rootStore";
import { GETALL_CONVERSATIONS } from "./conversationQueries";

export class ConversationStore {
    rootStore
    conversations: Conversation[] = []
    isLoading = true

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    async init() {
        //console.log("loading stuff")
        this.isLoading = true
        const response = await client.query({
            query: GETALL_CONVERSATIONS,
            fetchPolicy: "network-only",
        });
        if (response && response.data) {
            response.data.msgbox_Conversation.forEach((conv: any) => {
                var conversation = { folderId: conv.FolderId, conversationId: conv.ConversationId, contactId: conv.ContactId, assignedTo: conv.AssignedTo, assignedToDepartment: conv.AssignedToDepartment, isRead: conv.IsRead, lastMessage: new Date(conv.LastMessage), updatedOn: new Date(conv.UpdatedOn) }
                //console.log("item:", conversation)
                this.conversations.push(conversation)    
            })
        }
        this.isLoading = false
        //console.log("finished loading stuff")
        //console.log("conversations:", this.conversations)

        const socket = new WebSocket(`${process.env.REACT_APP_WSAPI_URL}?&token=${localStorage.getItem("access_token")}`)
        socket.addEventListener("open", event => {
            socket.send("Connection established")
          });
          
          // Listen for messages
          socket.addEventListener("message", event => {          
            //console.log("Message from server ", event.data)
            var incomingItem = JSON.parse(event.data);
            //console.log("incomingItem.conversationId:", incomingItem.conversationId)
            if (incomingItem && incomingItem.type === "conversation_update") {  
                //console.log("index:", this.conversations.findIndex(x => x.conversationId === incomingItem.conversationId))
                const existingItem: Conversation | undefined = this.conversations.find(x => x.conversationId === incomingItem.conversationId)
                //console.log("item:", incomingItem.item)
                if (existingItem) {
                    existingItem.folderId = incomingItem.item.FolderId
                    existingItem.assignedTo = incomingItem.item.AssignedTo
                    existingItem.assignedToDepartment = incomingItem.item.AssignedToDepartment
                    existingItem.isRead = incomingItem.item.IsRead
                    existingItem.contactId = incomingItem.item.ContactId
                    existingItem.lastMessage = incomingItem.item.LastMessage
                    existingItem.updatedOn = incomingItem.item.UpdatedOn
                    //console.log("item updated:", existingItem)
                } else {
                    const newItem: Conversation = { conversationId: incomingItem.conversationId, folderId: incomingItem.folderId, assignedTo: incomingItem.assignedTo, assignedToDepartment: incomingItem.assignedToDepartment, isRead: incomingItem.isRead, contactId: incomingItem.contactId, lastMessage: incomingItem.lastMessage, updatedOn: incomingItem.updatedOn } 
                    this.conversations.push(newItem)
                    //console.log("new item added")
                }
            }
            //console.log("conversation length:", this.conversations.length)
          });  
    }

    getConversation(conversationId: number) {
        return this.conversations.filter(x => x.conversationId === conversationId) 
    }


    getConversationCount() {
        return this.conversations.length;
    }

    getConversationForContact(contactId: number) {
        //console.log("getting conversation for contactid:", contactId)
        var conversation = this.conversations.filter(x => x.contactId === contactId)
        if (conversation && conversation[0]) {
            return conversation[0]
        }
        return null
    }
}

export class Conversation {
    folderId: number
    conversationId: number
    contactId: number
    assignedTo: number
    assignedToDepartment: number
    isRead: boolean
    lastMessage: Date
    updatedOn: Date

    constructor(folderId: number, conversationId: number, contactId: number, assignedTo: number, assignedToDepartment: number, isRead: boolean, lastMessage: Date, updatedOn: Date) {
        makeAutoObservable(this, { folderId: false, conversationId: false, contactId: false, assignedTo: false, assignedToDepartment: false, isRead: false, lastMessage: false, updatedOn: false })
        this.folderId = folderId
        this.conversationId = conversationId
        this.contactId = contactId
        this.assignedTo = assignedTo
        this.assignedToDepartment = assignedToDepartment
        this.isRead = isRead
        this.lastMessage = lastMessage
        this.updatedOn = updatedOn
    }
}


