import { gql } from "@apollo/client";

export const GET_TEMPLATE_MESSAGES = gql`
	query getTemplateMessages(
		$limit: Int
		$offset: Int
		$order_by: msgbox_Template_order_by!
		$status: msgbox_TemplateStatus_enum
		$searchTerm: String
		$userInboxes: [Int!]!
	) {
		msgbox_Template(
			limit: $limit
			offset: $offset
			order_by: [$order_by]
			where: {
				Status: { _eq: $status }
				Name: { _ilike: $searchTerm }
				aPIAccountByApiaccount: {
					Inboxes: { InboxId: { _in: $userInboxes } }
				}
			}
		) {
			Name
			Status
			TemplateText
			ButtonMetadata
			TemplateId
			Attachment
			LanguageCode
			AttachmentId
			TemplateExample
			RejectionReason
			TemplateCategoryId
			TemplateTypeId
			TemplateCategory {
				Name
				TemplateCategoryId
			}
			TemplateType {
				Description
			}
			aPIAccountByApiaccount {
				Name
				APIAccountId
			}
		}
		msgbox_Template_aggregate(
			where: {
				Status: { _eq: $status }
				Name: { _ilike: $searchTerm }
				aPIAccountByApiaccount: {
					Inboxes: { InboxId: { _in: $userInboxes } }
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const ADD_TEMPLATE_MESSAGE = gql`
	mutation addTemplateMessage(
		$name: String!
		$messageText: String!
		$attachment: String!
		$category: Int!
		$templateType: Int!
		$account: Int!
		$exampleMessage: String!
		$attachmentId: Int
		$language: String!
		$buttonMetaData: String
	) {
		insert_msgbox_Template(
			objects: {
				Name: $name
				TemplateText: $messageText
				TemplateCategoryId: $category
				Attachment: $attachment
				TemplateTypeId: $templateType
				APIAccount: $account
				TemplateExample: $exampleMessage
				AttachmentId: $attachmentId
				LanguageCode: $language
				ButtonMetadata: $buttonMetaData
			}
		) {
			affected_rows
		}
	}
`;

export const DELETE_TEMPLATE_MESSAGE = gql`
	mutation deleteTemplateM($templateMessageId: Int!) {
		update_msgbox_Template(where: {TemplateId: {_eq: $templateMessageId}}, _set: {IsDeleted: true, DeletedOn: "now()"}) {
		affected_rows
		}
	}`;

export const UPDATE_TEMPLATE_MESSAGE = gql`
	mutation updateTemplateMessage(
		$id: Int!
		$name: String!
		$messageText: String!
		$attachment: String!
		$category: Int!
		$templateType: Int!
		$exampleMessage: String!
		$attachmentId: Int
		$language: String!
		$buttonMetaData: String
	) {
		update_msgbox_Template(
			where: { TemplateId: { _eq: $id } }
			_set: {
				Name: $name
				TemplateText: $messageText
				TemplateCategoryId: $category
				Attachment: $attachment
				TemplateTypeId: $templateType
				TemplateExample: $exampleMessage
				AttachmentId: $attachmentId
				LanguageCode: $language
				ButtonMetadata: $buttonMetaData
        		Status: Pending
				RejectionReason: null
			}
		) {
			affected_rows
		}
	}
`;

export const GET_TEMPLATE_CATEGORIES = gql`
	query getTemplateCategories {
		msgbox_TemplateCategory(where: { IsEnabled: { _eq: true } }) {
			TemplateCategoryId
			Name
		}
	}
`;

export const GET_TEMPLATE_TYPES = gql`
	query getTemplateTypes {
		msgbox_TemplateType {
			Description
			TemplateTypeId
		}
	}
`;

export const GET_APIACCOUNT_FROM_INBOXID = gql`
	query GetApiAccountFromInboxId($inboxId: Int!) {
		msgbox_APIAccount(where: { Inboxes: { InboxId: { _eq: $inboxId } } }) {
			APIAccountId
		}
	}
`;

export const GET_TEMPLATE_LANGUAGES = gql`
	query GetTeplateLanguages {
		msgbox_TemplateLanguage(order_by: { Order: asc }) {
			LanguageCode
			Description
		}
	}
`;
