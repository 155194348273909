import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import client from "../../../../Apollo";
import { GET_ACCOUNT } from "../../../../Components/APIAccountsSelect/query";
import Button from "../../../../Components/Button/button.component";
import MsgBoxSelect from "../../../../Components/MsgBoxSelect/msg-box-select.component";
import { getUserId } from "../../../../helpers/functions";
import { FormField, Label } from "../../../../Styles/global";
import { importContactsValidationSchema } from "../validation";

export const IMPORT_OPTIONS = {
	SKIP: "skip",
	REPLACE: "replace",
	MERGE: "merge",
};

const SelectAccount = ({ setSelectedAccount }) => {
	const [accountDefault, setAccountDefault] = useState("");
	const [accounts, setAccounts] = useState([]);
	const { addToast } = useToasts();

	const loadAccountOptions = async () => {
		try {
			const response = await client.query({
				query: GET_ACCOUNT,
				variables: {
					userId: getUserId(),
				},
			});
			const options = response.data.msgbox_User[0].UserInboxes.filter((item) => item.Inbox).map(
				(inbox) => ({
					label: inbox.Inbox.APIAccount.Name,
					value: inbox.Inbox.APIAccount.APIAccountId,
				})
			);
			if (options.length === 1) {
				setAccountDefault(options[0]);
			}
			setAccounts(options);
		} catch (error) {
			console.log("error", error);
			addToast("An error occured", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	useEffect(() => {
		loadAccountOptions();
	}, []);

	return (
		<>
			<div>Please select account to be able to upload contacts.</div>
			<Formik
				initialValues={{
					account: accountDefault,
					importOption: IMPORT_OPTIONS.SKIP,
				}}
				onSubmit={(values) => {
					setSelectedAccount(values);
				}}
				validationSchema={importContactsValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({ handleSubmit, setFieldValue, values, errors }) => (
					<form id="select_account" onSubmit={handleSubmit}>
						<Field
							placeholder="Select Account"
							label="Select Account"
							key={1}
							name="account"
							onChange={(options) => {
								setFieldValue("account", options);
							}}
							options={accounts}
							value={values.account}
							error={errors.account}
							component={MsgBoxSelect}
							toolTipText="Select an account that you wish messages to new contact to be sent from"
							hasTooltip
							required
						/>
						<FormField>
							<Label id="import-option">
								Duplicated contacts*
							</Label>

							<div
								role="group"
								aria-labelledby="import-option"
								style={{ display: "flex" }}
							>
								<label style={{ marginRight: "24px" }}>
									<input
										type="radio"
										id={IMPORT_OPTIONS.SKIP}
										name="importOption"
										value={IMPORT_OPTIONS.SKIP}
										onChange={() =>
											setFieldValue(
												"importOption",
												IMPORT_OPTIONS.SKIP
											)
										}
										checked={
											values.importOption ===
											IMPORT_OPTIONS.SKIP
										}
									/>
									Skip contacts
								</label>
								<label style={{ marginRight: "24px" }}>
									<input
										type="radio"
										id={IMPORT_OPTIONS.REPLACE}
										name="importOption"
										value={IMPORT_OPTIONS.REPLACE}
										onChange={() =>
											setFieldValue(
												"importOption",
												IMPORT_OPTIONS.REPLACE
											)
										}
										checked={
											values.importOption ===
											IMPORT_OPTIONS.REPLACE
										}
									/>
									Replace existing tags
								</label>{" "}
								<label style={{ marginRight: "24px" }}>
									<input
										type="radio"
										id={IMPORT_OPTIONS.MERGE}
										name="importOption"
										value={IMPORT_OPTIONS.MERGE}
										onChange={() =>
											setFieldValue(
												"importOption",
												IMPORT_OPTIONS.MERGE
											)
										}
										checked={
											values.importOption ===
											IMPORT_OPTIONS.MERGE
										}
									/>
									Merge tags
								</label>
							</div>
						</FormField>
						<Button
							primary
							type="submit"
							buttonName="Start import"
						/>
					</form>
				)}
			</Formik>
		</>
	);
};

export default SelectAccount;
