import React, { useEffect, useState, useRef, useContext } from "react";
import {
	GET_TEMPLATE_MESSAGES,
	ADD_TEMPLATE_MESSAGE,
	DELETE_TEMPLATE_MESSAGE,
	GET_TEMPLATE_CATEGORIES,
	GET_TEMPLATE_TYPES,
	GET_APIACCOUNT_FROM_INBOXID,
	GET_TEMPLATE_LANGUAGES,
	UPDATE_TEMPLATE_MESSAGE,
} from "./query";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import TextArea from "../TextArea/text-area.component";
import Input from "../Input/input.component";
import Pill from "../Pill/pill.component";
import MsgBoxTable from "../MsgBoxTable/msg-box-table.component";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { Formik, Field } from "formik";
import { URL_REGEX, templateMessageValidationSchema } from "./validationSchema";
import client from "../../Apollo";
import { CheckboxLabel, TemplateMessageForm } from "./styles";
import ExpandableTemplateComponent from "./expanded-rows.component";
import Legend from "./legend.component";
import { GET_ACCOUNT } from "../APIAccountsSelect/query";
import { getUserId } from "../../helpers/functions";
import { Label, TableFiltersContainer } from "../../Styles/global";
import { $userInboxes } from "../../store";
import ReactGA from "react-ga4";
import UploadTemplateAttachment from "./upload-template-attachment";
import { Checkbox } from "./styles";
import { PHONE_REGEX } from "../../Pages/Contacts/bulk-contact-import/validation";
import { GET_TEMPLATE_MESSAGE_BY_EXACT_NAME } from "../new-natural-chat/query";
import { UserContext } from "../../Providers/user-provider";
import { MenuItem, ControlledMenu, MenuHeader } from "@szhsin/react-menu";

export const ATTACHMENT_TYPE_SELECT_OPTIONS = [
	{
		label: "No attachment",
		value: "None",
	},
	{
		label: "Image",
		value: "Image",
	},
	{
		label: "Document",
		value: "Document",
	},

	{
		label: "Video",
		value: "Video",
	},
];

const formValues = {
		name: "",
		category: "",
		text: "",
		attachment: {
		label: "No Attachments",
		value: "None",
		},
		type: {
			label: "",
			value: ""
		},
		language: {
			label: "English (UK)",
			value: "en_GB",
		},
		feature: {
			label: "None",
			value: false
		},
		quickReplyButtons: [],
		callToActionButtons: [],
		account: "",
		attachmentId: null,
		exampleMessage: "",
		editing: false
	}

const TemplateMessages = () => {
	const [initialFormValues, setInitalFormValues] = useState(formValues)
	const [modalIsOpen, setModalOpen] = useState(false);
	const [categories, setCategories] = useState([]);
	const [templateTypes, setTemplateTypes] = useState([]);
	const [accountOptions, setAccountOptions] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [addFormIsDirty, setAddFormDirty] = useState(false);
	const [initalTemplateType, setInitalTemplateType] = useState([]);
	const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
	const [exampleMessage, setExampleMessage] = useState("");
	const [uniqueNameError, setUniqueNameError] = useState("")
	const [showPreview, setShowPreview] = useState(false)
	const [buttonDropdown, setButtonDropdown] = useState(false)
	const buttonRef = useRef(null);

	const user = useContext(UserContext)
	// Filter status
	const [filters, setFilters] = useState(null);
	const [insertTemplateMessage, { loading: addTemplateLoading }] =
		useMutation(ADD_TEMPLATE_MESSAGE);
	const [deleteTemplateMessage, ,] = useMutation(DELETE_TEMPLATE_MESSAGE);
	const [editTemplateMessage] = useMutation(UPDATE_TEMPLATE_MESSAGE)
	const { addToast } = useToasts();
	const tableRef = useRef();

	const userInboxes = useReactiveVar($userInboxes);

	const columns = [
		{
			name: "Template Name",
			selector: "templateName",
			sortable: true,
			sortField: "Name",
		},
		{
			name: "Status",
			selector: "status",
			resizable: true, 
			cell: (row) => (
				<Pill style={{ backgroundColor: "blue" }} name={row.status} />
			),
		},
		{
			name: "Rejection reason",
			selector: "rejectionReason",
			width: '200px',
			cell: (row) => <div>{row.rejectionReason}</div>,
		},
		{
			name: "Account",
			selector: "account",
			cell: (row) => <div>{row.account.label}</div>,		
		},
		{
			name: "Attachment",
			selector: "attachment",
			cell: (row) => <div>{row.attachment.label}</div>,			
		},
		{
			name: "Category",
			selector: "category",
			cell: (row) => <div>{row.category.label}</div>,			
		},
		{
			name: "Template Type",
			selector: "type",
			cell: (row) => <div>{row.type.label}</div>,
		},
	];

	useEffect(() => {
		setFilters({
			...filters,
			userInboxes: userInboxes,
		});
	}, []);

	useEffect(() => {
		loadAccountOptions();
		fetchTemplateCategories();
		fetchTemplateTypes();
		fetchLanguageCodes();
		setUniqueNameError("")
	}, [modalIsOpen]);

	const resetModal = () => {
		setModalOpen(false);
		setAddFormDirty(false);
	};

	const fetchLanguageCodes = async (data) => {
		const res = await client.query({
			query: GET_TEMPLATE_LANGUAGES,
		});
		const languages = res.data.msgbox_TemplateLanguage.map((category) => {
			return {
				label: category.Description,
				value: category.LanguageCode,
			};
		});
		setLanguages(languages);
	};

	const fetchTemplateCategories = async (data) => {
		const res = await client.query({
			query: GET_TEMPLATE_CATEGORIES,
		});
		const categories = res.data.msgbox_TemplateCategory.map((category) => {
			return {
				label: category.Name,
				value: category.TemplateCategoryId,
			};
		});
		setCategories(categories);
	};

	const fetchTemplateTypes = async () => {
		const res = await client.query({
			query: GET_TEMPLATE_TYPES,
		});
		const defaultType = res.data.msgbox_TemplateType.filter(
			(type) => type.Description === "All"
		);
		const types = res.data.msgbox_TemplateType.map((type) => {
			return {
				label: type.Description,
				value: type.TemplateTypeId,
			};
		});
		setTemplateTypes(types);
		setInitalTemplateType(defaultType);
		let temp = {
			...formValues,
			type: {
				label: defaultType[0]?.Description,
				value: defaultType[0]?.TemplateTypeId,
			}
		}
		setInitalFormValues(temp)
	};

	function editValidation (r) {
		if(r[0].status !== "Pending") return true
		return false
	}

	const onDeleteTemplateMessage = async (templateMessage) => {
		const promises = templateMessage.map((row) =>
			deleteTemplateMessage({ variables: { templateMessageId: row.id } })
		);
		await Promise.all(promises);
		addToast("Template messages deleted", {
			appearance: "success",
			autoDismiss: true,
		});
		tableRef.current.refetchData();
		ReactGA.event({
			category: "Templates",
			action: "TemplateDeleted",
		});
	};

	const fetchApiAccountId = async (inboxId) => {
		const { data } = await client.query({
			query: GET_APIACCOUNT_FROM_INBOXID,
			variables: {
				inboxId: inboxId,
			},
		});
		if (data && data.msgbox_APIAccount[0]) {
			return data.msgbox_APIAccount[0].APIAccountId;
		}
		return null;
	};

	function handleEdit(template) {
		let temp = {
			...formValues,
			editing: template.id,
			attachment: template.attachment,
			attachmentId: template.attachmentId,
			exampleMessage: template.exampleMessage,
			text: template.unedtiedText,
			name: template.templateName,
			type: template.type,
			callToActionButtons: [],
			quickReplyButtons: []
		}
		let buttonmeta = JSON.parse(template.ButtonMetadata) || []

		buttonmeta.forEach((item) => {
			if(item.type == "QUICK_REPLY") {
				temp.quickReplyButtons.push({type: "basic", title: item.text})
			} else if (item.type == "URL") {
				if(item.url.endsWith("/(1)")){
					let newURL = item.url.substring(0, item.url.length - 3);
					temp.callToActionButtons.push({type: "dynamicURL", url: newURL, title: item.text})
				} else {
					temp.callToActionButtons.push({type: "staticURL", url: item.url, title: item.text})
				}
			} else if(item.type == "PHONE_NUMBER" ){ 
				temp.callToActionButtons.push({type: "phone", title: item.text, phone: item.phone_number})
			}
		}) 


		const lang = languages.filter((item) => template.LanguageCode == item.value)
		if(lang[0]) temp.language = lang[0]
		const acc = accountOptions.filter((item) => template.account.label == item.label)
		if(acc[0]) temp.account = [acc[0]]
		const cat = categories.filter((item) => template.category.value == item.value)
		if(cat[0]) temp.category = cat[0]
		setInitalFormValues(temp)
		
		setExampleMessage(temp.exampleMessage)
		setModalOpen(true)
		
	}

	const addTemplateMessage = async (values, actions) => {
		let templateExists = await client.query({
			query: GET_TEMPLATE_MESSAGE_BY_EXACT_NAME,
			variables: {
				name: values.name,
				userInboxes: values.account.map((item) => item.value)
			}
		})

		if(templateExists.data.msgbox_Template.length > 0 && (values.editing == false || templateExists.data.msgbox_Template[0].TemplateId !== values.editing)) {
			 return setUniqueNameError("Template name must be unique in selected Inboxes")
		} else {
			setUniqueNameError("")
		}

		let buttonMetaData = null

		
		let arrQ = values.quickReplyButtons.map((item) => {
			return {"type":"QUICK_REPLY","text":item.title}
		  })
	  
		let arrC = values.callToActionButtons.map((item) => {
		if(item.type == "staticURL") {
			return {"type": "URL","text": item.title,"url": item.url }
		} else if (item.type == "dynamicURL") {
			return {"type": "URL","text": item.title, "url": item.url.endsWith("/") ? item.url + "{1}" : item.url + "/{1}" }
		} else {
			let newNum = item.phone.replace("+", "")
					return {"type": "PHONE_NUMBER","text": item.title,"phone_number": newNum }
		}
		})
	
		if(arrC.length !== 0 || arrQ.length !== 0) buttonMetaData = JSON.stringify([...arrC, ...arrQ])
	  
		if(values.editing){
			await editTemplateMessage({
				variables: {
					id: values.editing,
					messageText: values.text.replace(/ {2,}/g, ' ').replaceAll("{{InboxName}}", values.account[0].label),
					name: values.name,
					category: values.category.value,
					attachment: values.attachment.value,
					templateType: values.type.value,
					exampleMessage: values.exampleMessage.replace(/ {2,}/g, ' ').replaceAll("[InboxName]", values.account[0].label),
					attachmentId: values.attachmentId,
					language: values.language.value,
					buttonMetaData: buttonMetaData
				},
			});
			addToast("Template edited", {
				appearance: "success",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Templates",
				action: "TemplateEdited",
			});
		} else {	
			for(let i = 0; i < values.account.length; i++){	
				const apiAccountId = await fetchApiAccountId(values.account[i].value);
				await insertTemplateMessage({
					variables: {
						messageText: values.text.replace(/ {2,}/g, ' ').replaceAll("{{InboxName}}", values.account[i].label),
						name: values.name,
						category: values.category.value,
						attachment: values.attachment.value,
						templateType: values.type.value,
						account: apiAccountId,
						exampleMessage: values.exampleMessage.replace(/ {2,}/g, ' ').replaceAll("[InboxName]", values.account[i].label),
						attachmentId: values.attachmentId,
						language: values.language.value,
						buttonMetaData: buttonMetaData
					},
				})
			}
			addToast("Template added", {
				appearance: "success",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Templates",
				action: "TemplateAdded",
			});
		}
		setModalOpen(false);
		tableRef.current.refetchData();
	};

	const loadAccountOptions = async () => {
		try {
			const response = await client.query({
				query: GET_ACCOUNT,
				variables: {
					userId: getUserId(),
				},
			});
			const options = response.data.msgbox_User[0].UserInboxes.filter((item) => item.Inbox).map(
				(inbox) => ({
					label: inbox.Inbox.APIAccount.Name,
					value: inbox.Inbox.InboxId,
				})
			);

			setAccountOptions(options);
		} catch (error) {
			console.log("err", error);
		}
	};

	const submitForm = (values, actions) => {
		addTemplateMessage(values, actions);
	};

	const formatData = (data) => {
		const templates = data.msgbox_Template.map((template) => {
			let withButtons = " "
			if(template.ButtonMetadata) {
				JSON.parse(template.ButtonMetadata).forEach((button) => {
					withButtons += `| [${button.text}] `
				})
			}
			return {
				id: template.TemplateId,
				messageText: template.TemplateText + withButtons,
				unedtiedText: template.TemplateText,
				status: template.Status,
				templateName: template.Name,
				/**
				 * Set "category", "type" and "attachment".. and account as an object so that when a row is cliked we know the id that the
				 * text is associated with. Required because when we prepopulate the update modal the select components need their values to == {label: string, value: any}
				 */
				category: {
					label: template.TemplateCategory?.Name,
					value: template.TemplateCategoryId,
				},
				type: {
					label: template.TemplateType.Description,
					value: template.TemplateTypeId,
				},
				attachment: {
					label: template.Attachment,
					value: template.Attachment,
				},
				attachmentId: template.AttachmentId,
				ButtonMetadata: template.ButtonMetadata,
				exampleMessage: template.TemplateExample,
				LanguageCode: template.LanguageCode,
				rejectionReason: template.RejectionReason
					? template.RejectionReason
					: "N/A",
				account: {
					label: template.aPIAccountByApiaccount.Name,
					value: template.aPIAccountByApiaccount.APIAccountId,
				},
			};
		});
		return [templates, data.msgbox_Template_aggregate.aggregate.count];
	};

	const handleSearchChange = (searchTerm) => {
		setFilters({
			...filters,
			searchTerm: "%" + searchTerm + "%",
		});
	};

	const handleAccountFilterChange = (name, option) => {
		let inbox = option.value == null ? userInboxes : option.value;
		setFilters({
			...filters,
			userInboxes: inbox,
		});
	};

	const moveText = (values) => {
			var exampleMsg = values.text;
			exampleMsg = exampleMsg.replaceAll(/\{\{\d+\}\}/g, "[test]");
            exampleMsg = exampleMsg.replaceAll("{{InboxName}}", "[InboxName]");
			setExampleMessage(exampleMsg);
			values.exampleMessage = exampleMsg;
	};

	const updateExampleMessage = (event) => {
		setExampleMessage(event.target.value);
	};

	return (
		<div>
			<MsgBoxModal
				aria={{
					labelledby: "heading",
					describedby: "description",
				}}
				isOpen={modalIsOpen}
				onRequestClose={() => {
					resetModal();
				}}
				description="Use the form below to add a template message. Name must at least 5 characters, be lower case, numbers and _ only. The template must not start, or end with a variable field."
				header="Add template message"
				cancel={() => {
					resetModal();
				}}
				submitId="template_message"
				customModalContentStyles={{
					width: "80%",
					overflowY: 'auto'
				}}
				submitting={addTemplateLoading}
				submitButtonDisabled={addFormIsDirty === false}
			>
				<Formik
					initialValues={initialFormValues}
					onSubmit={submitForm}
					validationSchema={templateMessageValidationSchema}
					validateOnChange={false}
					validateOnBlur={false}
				>
					{({
						handleSubmit,
						handleChange,
						setFieldValue,
						values,
						errors,
						setFieldTouched,
					}) => (
						<TemplateMessageForm
							id="template_message"
							onSubmit={handleSubmit}

						>
							<div className="column-one">
								<Input
									name="name"
									type="text"
									value={values.name}
									label="Name"
									onChange={handleChange}
									placeholder="Name"
									error={errors.name || uniqueNameError}
									required
									disabled={values.editing}
								/>
								
								
						
								{
									!values.editing &&
										<Field
											key={4}
											label="Account"
											name="account"
											onChange={(options, action) => {
												setFieldValue("account", options);
												setAddFormDirty(true);
											}}
											onBlur={() => {
												setFieldTouched("account", true);
											}}
											isMulti
											options={accountOptions}
											value={values.account}
											error={errors.account}
											component={MsgBoxSelect}
											toolTipText="Select accounts that you wish this message to be sent from"
											hasTooltip
											required
										/>
								}

								<Field
									key={5}
									label="Language"
									name="language"
									onChange={(options, action) => {
										setFieldValue("language", options);

										setAddFormDirty(true);
									}}
									onBlur={() => {
										setFieldTouched("language", true);
									}}
									options={languages}
									value={values.language}
									error={errors.language}
									component={MsgBoxSelect}
									toolTipText="Template Language"
									hasTooltip
									required
								/>	
								<MsgBoxSelect
									key={1}
									placeholder="Select a category"
									name="category"
									options={categories}
									onChange={(options, action) => {
										setFieldValue("category", options);

										setAddFormDirty(true);
									}}
									value={values.category}
									label="Select a category"
									error={errors.category}
									required
								/>
								<MsgBoxSelect
									key={3}
									placeholder="Select a type"
									name="type"
									options={templateTypes}
									onChange={(options, action) => {
										setFieldValue("type", options);
										setAddFormDirty(true);
									}}
									label="Select a type"
									error={errors.type}
									value={values.type}
									required
								/>
								<MsgBoxSelect
									key={2}
									placeholder="Add an attachment"
									name="attachment"
									menuPlacement="top"
									options={ATTACHMENT_TYPE_SELECT_OPTIONS}
									onChange={(option, action) => {
										setFieldValue("attachment", option);

										setAddFormDirty(true);
										if (option.value === "None") {
											setFieldValue("attachmentId", null);
											setFieldValue(
												"attachment",
												ATTACHMENT_TYPE_SELECT_OPTIONS[0]
											);
										} else {
											setAttachmentModalOpen(true);
										}
									}}
									label="Add an attachment"
									error={errors.attachment}
									value={values.attachment}
									required
								/>
							</div>
							<div className="column-two">
							<div className="mb-2" style={{ display: showPreview ? 'none' : 'block' }}>

								<TextArea
									style={{ height: "147px" }}
									name="text"
									label="Message Text"
									placeholder="Template message text here, include placeholders such as {{1}} for variable fields"
									value={values.text}
									onChange={(event) => {
										handleChange(event);
										setAddFormDirty(true);
									}}
									id="messageText"
									onBlur={() => moveText(values)}
									error={errors.text}
									toolTipText="Enter your template message in the following format. Hi {{1}}, your valuation appointment is confirmed for {{2}}"
									required
									hasTooltip
								/>
							</div>
							<div className="mb-2" style={{ display: showPreview ? 'block' : 'none' }}>
								<TextArea
									style={{ height: "132px" }}
									name="exampleMessage"
									label="Example Message"
									placeholder="Example message text here, include example values such as [the property details you request] in the variable fields"
									value={exampleMessage}
									onChange={(event) => {
										handleChange(event);
										setAddFormDirty(true);
										updateExampleMessage(event);
									}}
									error={errors.exampleMessage}
									toolTipText="Enter an example message in the same format as above but example variables in place of the curley braces above/"
									required
									hasTooltip
								/>
							</div>


							<div className="mb-2" style={{display: 'flex', justifyContent: 'flex-end', gap: 5}}>
								<button
									type="button"
									className="btn btn-light"
									onClick={() => {
										setShowPreview(!showPreview) 
									}}
								>
									{showPreview ? "Show Message" : "Show Preview"}
								</button>
								<button
									type="button"
									className="btn btn-light"
									onClick={() => {
									const textarea = document.querySelector('#messageText');
									if (!textarea) return;
									
									const start = textarea.selectionStart;
									const end = textarea.selectionEnd;
									const text = textarea.value;
									const before = text.substring(0, start);
									const after = text.substring(end, text.length);
									
									let exampleMsg = before + "{{InboxName}}" + after;
									values.text = exampleMsg
									exampleMsg = exampleMsg.replaceAll(/\{\{\d+\}\}/g, "[test]");
									exampleMsg = exampleMsg.replaceAll("{{InboxName}}", "[InboxName]");
				
									setExampleMessage(exampleMsg);
									setTimeout(() => {
										textarea.selectionStart = textarea.selectionEnd = start + 1;
									}, 0);
									setAddFormDirty(true);
									}}
									>
									Inbox Name
								</button>
								<button
									type="button"
									className="btn btn-light"
									onClick={() => {
									const textarea = document.querySelector('#messageText');
									if (!textarea) return;
									
									const start = textarea.selectionStart;
									const end = textarea.selectionEnd;
									const text = textarea.value;
									const before = text.substring(0, start);
									const after = text.substring(end, text.length);
									
									if (start === end) {
										const newText = before + "*" + after;
										values.text = newText
										let exampleMsg = newText.replaceAll(/\{\{\d+\}\}/g, "[test]");
										exampleMsg = exampleMsg.replaceAll("{{InboxName}}", "[InboxName]");
										setExampleMessage(exampleMsg)
										setTimeout(() => {
										textarea.selectionStart = textarea.selectionEnd = start + 1;
										}, 0);
									} else {
										const selectedText = text.substring(start, end);
										const newText = before + "*" + selectedText + "*" + after;
										values.text = newText
										let exampleMsg = newText.replaceAll(/\{\{\d+\}\}/g, "[test]");
										exampleMsg = exampleMsg.replaceAll("{{InboxName}}", "[InboxName]");
										setExampleMessage(exampleMsg)
										setTimeout(() => {
										textarea.selectionStart = start;
										textarea.selectionEnd = end + 2; 
										}, 0);
									}
									setAddFormDirty(true);
									}}
									>
									B
								</button>
								<button
									type="button"
									className="btn btn-light"
									onClick={() => {
									const textarea = document.querySelector('#messageText');
									if (!textarea) return;
									
									const start = textarea.selectionStart;
									const end = textarea.selectionEnd;
									const text = textarea.value;
									const before = text.substring(0, start);
									const after = text.substring(end, text.length);
									
									if (start === end) {
										const newText = before + "_" + after;
										values.text = newText
										let exampleMsg = newText.replaceAll(/\{\{\d+\}\}/g, "[test]");
										exampleMsg = exampleMsg.replaceAll("{{InboxName}}", "[InboxName]");
										setExampleMessage(exampleMsg)
										setTimeout(() => {
										textarea.selectionStart = textarea.selectionEnd = start + 1;
										}, 0);
									} else {
										const selectedText = text.substring(start, end);
										const newText = before + "_" + selectedText + "_" + after;
										values.text = newText
										let exampleMsg = newText.replaceAll(/\{\{\d+\}\}/g, "[test]");
										exampleMsg = exampleMsg.replaceAll("{{InboxName}}", "[InboxName]");
										setExampleMessage(exampleMsg)
										setTimeout(() => {
										textarea.selectionStart = start;
										textarea.selectionEnd = end + 2; 
										}, 0);
									}
									setAddFormDirty(true);

									}}
								>
									I
								</button>
								<button
									type="button"
									className="btn btn-light"
									onClick={() => {
										values.text = values.text + ` {{${(values.text?.match(/{{(\d+)}}/g) || [])?.length + 1}}}`
										setExampleMessage(exampleMessage + ` [${(values.text?.match(/{{(\d+)}}/g) || [])?.length + 1}]`)
										setAddFormDirty(true);
									}}
								>
									{`{{${(values.text?.match(/{{(\d+)}}/g) || [])?.length + 1}}}`}
								</button>
						</div>



						</div>
							
							<div className="column-three">

							<button
								type="button"
								className="btn btn-primary"
								onClick={() => setButtonDropdown(!buttonDropdown)}
								ref={buttonRef}
								style={{marginLeft: "auto", padding: "10px 20px"}}
							>
								Buttons
							</button>
								<ControlledMenu
									isOpen={buttonDropdown}
									anchorRef={buttonRef}
									onClose={() => 
										setButtonDropdown(false)
									}
									position={"start"}
									direction={"left"}
									
								>
										<MenuHeader>Quick Reply Buttons</MenuHeader>
										<MenuItem
											disabled={values.quickReplyButtons.length > 9}
											onClick={() => {
												values.quickReplyButtons = [...values.quickReplyButtons, {type: "basic", title: ""}]
												setAddFormDirty(true);
											}}>
												Quick Reply
										</MenuItem>
										<MenuHeader>Call To Action Buttons</MenuHeader>
										<MenuItem 
											disabled={values.callToActionButtons.filter((item) => item.type == "phone").length == 1}
										onClick={() => {
											values.callToActionButtons = [...values.callToActionButtons, {type: "phone", title: "", phone: ""}]
											setAddFormDirty(true);

										}}>
											Call To Action - Phone Number
										</MenuItem>
										<MenuItem
											disabled={values.callToActionButtons.filter((item) => item.type == "staticURL").length == 2}
											onClick={() => {
												values.callToActionButtons = [...values.callToActionButtons, {type: "staticURL", url: "", title: ""}]
												setAddFormDirty(true);

											}}>
												Call To Action - Static URL
										</MenuItem>
							
									</ControlledMenu>
								

			<div>
				{values.quickReplyButtons.length > 0 && 
                <div>
                    <h5 style={{margin: "10px 0px"}}>Quick Reply</h5>
                    {
                      values.quickReplyButtons.map((item, index) => {
                        if(item.type=="basic")return (
                          <div key={"quickReply" + index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                            <div style={{flex: 1}}>
                            <Input
                              type="text"
							  placeholder="Enter Title"
							  label="Title"
                              onChange={(e) => {
								if(e.target.value.length > 20) return
                                const updatedButtons = [...values.quickReplyButtons];
                                updatedButtons[index].title = e.target.value;
								setFieldValue("quickReplyButtons", updatedButtons);
								setAddFormDirty(true);

                              }}
                              value={item.title}
                            />
                            </div>
                             <button
                              type="button"
                              style={{flexShrink: 1}}
                              className="btn btn-light"
                              onClick={() => {
                                const updatedButtons = [...values.quickReplyButtons];
                                updatedButtons.splice(index, 1);
								setFieldValue("quickReplyButtons", updatedButtons);
								setAddFormDirty(true);

                              }}
                            >
                              &#10006;
                            </button>
                          </div>
                          )
                      })
                    }
				<p style={{color: 'red'}}>{errors.quickReplyButtons}</p>
                </div>
                  }
                  {values.callToActionButtons.length > 0 && 
                <div>
                    <h5 style={{margin: "10px 0px"}}>Call To Action</h5>
                    {
                      values.callToActionButtons.map((item, index) => {
                        if(item.type=="phone") return (
                          <div key={"callToActionNumber" + index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                            <div style={{flex: 1}}>
                            <Input
                              type="text"
                              placeholder="Enter Phone Number"
							  label="Phone Number"
                              onChange={(e) => {
                                const updatedButtons = [...values.callToActionButtons];
                                updatedButtons[index].phone = e.target.value;
								setFieldValue("callToActionButtons", updatedButtons);
								setAddFormDirty(true);

                              }}
                              value={item.phone}
                            />
                            </div>
                            <div style={{flex: 1}}>
                            <Input
							  label="Title"
                              type="text"
                              placeholder="Enter Title"
                              onChange={(e) => {
								if(e.target.value.length > 20) return
                                const updatedButtons = [...values.callToActionButtons];
                                updatedButtons[index].title = e.target.value;
								setFieldValue("callToActionButtons", updatedButtons);
								setAddFormDirty(true);

                              }}
                              value={item.title}
                            />
                            </div>
                             <button
                              type="button"
                              style={{flexShrink: 1}}
                              className="btn btn-light"
                              onClick={() => {
                                const updatedButtons = [...values.callToActionButtons];
                                updatedButtons.splice(index, 1);
								setFieldValue("callToActionButtons", updatedButtons);
								setAddFormDirty(true);

                              }}
                            >
                              &#10006;
                            </button>
                          </div>
                          )
                          if(item.type=="staticURL") return (
                            <div key={"callToActionNumber" + index} className="mb-2" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                              <div style={{flex: 1}}>
                              <Input
                                type="text"
                                placeholder="Enter URL"
								label="Static URL"
                                onChange={(e) => {
                                  const updatedButtons = [...values.callToActionButtons];
                                  updatedButtons[index].url = e.target.value;
								  setFieldValue("callToActionButtons", updatedButtons);
								  setAddFormDirty(true);

                                }}
                                value={item.url}
                              />
                              </div>
                              <div style={{flex: 1}}>
                              <Input
                                type="text"
                                placeholder="Enter Title"
								label="Title"
                                onChange={(e) => {
									if(e.target.value.length > 20) return
                                  const updatedButtons = [...values.callToActionButtons];
                                  updatedButtons[index].title = e.target.value;
								  setFieldValue("callToActionButtons", updatedButtons);
								  setAddFormDirty(true);

                                }}
                                value={item.title}
                              />
                              </div>
                               <button
                                type="button"
                                style={{flexShrink: 1}}
                                className="btn btn-light"
                                onClick={() => {
                                  const updatedButtons = [...values.callToActionButtons];
                                  updatedButtons.splice(index, 1);
								  setFieldValue("callToActionButtons", updatedButtons);
								  setAddFormDirty(true);

                                }}
                              >
                                &#10006;
                              </button>
                            </div>
                            )
                            
                      })
					  
                    }
					<p style={{color: 'red'}}>{errors.callToActionButtons}</p>
                </div>
				
                  }
                  {
                    (values.callToActionButtons.length == 0 && values.quickReplyButtons.length == 0 ) &&
                    <div className="mt-4">
                      <h5 style={{textAlign: 'center'}}>No Buttons Added</h5>
                    </div>
                  }


							</div>


							</div>
							
						
							<UploadTemplateAttachment
								isOpen={attachmentModalOpen}
								cancel={() => {
									setFieldValue("attachmentId", null);
									setFieldValue(
										"attachment",
										ATTACHMENT_TYPE_SELECT_OPTIONS[0]
									);
								}}
								closeModal={() => {
									setAttachmentModalOpen(false);
								}}
								setUploadedAttachmentId={(attachmentId) =>
									setFieldValue("attachmentId", attachmentId)
								}
								setUploadedAttachmentType={(attachmentType) => {
									setFieldValue("attachment", attachmentType);
								}}
							/>
						</TemplateMessageForm>
					)}
				</Formik>
			</MsgBoxModal>
			<MsgBoxTable				
				handleSearchChange={handleSearchChange}
				ref={tableRef}
				title="Template Messages"
				columns={columns}
				formatData={formatData}
				fetchRows={GET_TEMPLATE_MESSAGES}
				defaultOrderBy={{
					field: "Name",
					sortDirection: "asc",
				}}
				defaultLimit={10}
				deleteRows={onDeleteTemplateMessage}
				openModal={() => {
					let temp = {
						...formValues,
						type: {
							label: initalTemplateType[0]?.Description,
							value: initalTemplateType[0]?.TemplateTypeId,
						}
					}
					setInitalFormValues(temp)
					setModalOpen(true);
				}}
				subHeader
				subHeaderAlign="left"
				subHeaderComponent={[
					<TableFiltersContainer>
						<MsgBoxSelect
							hasTooltip
							toolTipText="Filter templates based on status"
							options={[
								{
									label: "Any",
									value: null,
								},
								{
									label: "Pending",
									value: "Pending",
								},
								{
									label: "Approved",
									value: "Approved",
								},
								{
									label: "Rejected",
									value: "Rejected",
								},
							]}
							onChange={(options) => {
								setFilters({
									...filters,
									status: options.value,
								});
							}}
							name="status"
							label="Status"
							table
						/>
						<MsgBoxSelect
							key={2}
							onChange={(options) => {
								handleAccountFilterChange("account", options);
							}}
							name="account"
							label="Account"
							table
							options={[
								...accountOptions,
								{ label: "Any", value: null },
							]}
						/>
					</TableFiltersContainer>,
					<Legend />,
				]}
				filters={filters}
				openModalButtonName="Add template message"
				
				expandableRows
				expandableRowsComponent={<ExpandableTemplateComponent />}
				searchPlaceHolder="Search template name..."
				pagination
				hasEdit={user.permissions.canAddEditTemplates}
				hasAddButton={user.permissions.canAddEditTemplates}
				hasDelete={user.permissions.canDeleteTemplates}
				editableValidation={editValidation}
				editRows={(e) => handleEdit(e[0])}
				paginationServer
				selectableRows
				expandOnRowClicked
				pointerOnHover
				sortServer
			/>
		</div>
	);
};

export default TemplateMessages;
