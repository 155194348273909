import { gql } from "@apollo/client";

export const GET_ALL_INBOXES = gql`
	query getInboxes {
		msgbox_Inbox {
			InboxId
			Name
		}
	}
`;
