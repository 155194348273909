import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useQuery } from "@apollo/client";
import { GET_ALL_INBOXES } from "./query";

const UserInboxSelect = ({ handleChange, ...rest }) => {
	const { data, loading, error } = useQuery(GET_ALL_INBOXES);

	if (error) return null;
	if (loading) return null;

	return (
		<MsgBoxSelect
			options={data.msgbox_Inbox.map((inbox) => ({
				label: inbox.Name,
				value: inbox.InboxId,
			}))}
			isMulti
			onChange={handleChange}
			{...rest}
		/>
	);
};

export default UserInboxSelect;
