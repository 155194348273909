import { useEffect, useState, useRef, useContext } from "react";
import { Helmet } from "react-helmet";
import { useQuery, makeVar } from "@apollo/client";
import { GET_UNREAD_CONVERSATIONS } from "./query";
import useWebSocket from "react-use-websocket";
import { UserContext } from "../../Providers/user-provider";
import { useTheme } from "styled-components";
import { useToasts } from "react-toast-notifications";
import { $reuseableDialog } from "../../store";

export const numberOfUnreadMessages = makeVar(0);

const UnreadConversations = () => {
	const { addToast } = useToasts();

	const theme = useTheme();
	const userContext = useContext(UserContext);
	const { loading, error, data, refetch } = useQuery(
		GET_UNREAD_CONVERSATIONS
	);

	useEffect(() => {
		if (
			data &&
			data.msgbox_Conversation_aggregate &&
			data.msgbox_Conversation_aggregate.aggregate
		) {
			numberOfUnreadMessages(
				data.msgbox_Conversation_aggregate.aggregate.count
			);
		}
	}, [data]);

	const { lastMessage } = useWebSocket(
		`${process.env.REACT_APP_WSAPI_URL}?orgid=${userContext.orgId}&token=${localStorage.getItem("access_token")}`,
		{
			reconnectAttempts: 100,
			reconnectInterval: (attemptNumber) =>
				Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
			share: true,
			shouldReconnect: (closeEvent) => true,
			onError: handleConnectionFailure,
		}
	);

	function handleConnectionFailure() {
		$reuseableDialog({
			visible: true,
			title: "Lost connection to the server",
			body: "You must refresh the page or no new messages will show",
			onPress: () => window.location.reload(),
			button: "Refresh",
		})
	}

	useEffect(() => {
		if (lastMessage && lastMessage.data) {
			const incomingData = JSON.parse(lastMessage.data);
			if(incomingData.type == "conversation_update") refetch();
		}
	}, [lastMessage]);

	const [title, setTitle] = useState(theme.appName);

	let count = useRef(null);

	// Handle title ticking
	useEffect(() => {
		let interval;
		if (data) {
			if (data.msgbox_Conversation_aggregate.aggregate.count > 0) {
				interval = setInterval(() => {
					count.current += 1;
					if (count.current % 2 === 0) {
						setTitle(
							`(${data.msgbox_Conversation_aggregate.aggregate.count}) New Message Arrived`
						);
					} else {
						setTitle(theme.appName);
					}
				}, 1000);
			} else {
				setTitle(theme.appName);
				clearInterval(interval);
				count.current = null;
			}
		}
		return () => clearInterval(interval);
	}, [data]);

	if (loading) return null;
	if (data) {
		return (
			<Helmet defer={false}>
				<title>{title}</title>
			</Helmet>
		);
	} else return null;
};

export default UnreadConversations;
