import React, {useEffect, useState} from 'react'
import { AlertsButton, Container } from "./styles";
import { useReactiveVar } from "@apollo/client";
import {
	$unreadInboxData,
	$unassignedConversationFilter,
	$assignedToMeConversationFilter,
	$unreadConversationFilter,
	$assignedToTeamsConversationFilter,
} from "../../store";

const Alerts = () => {
	const handleAlertsClick = () => {};

	const [badgeNumber, setBadgeNumber] = useState(0)

	const unreadInboxData = useReactiveVar($unreadInboxData);

	const assignedToMeConversationFilter = useReactiveVar(
		$assignedToMeConversationFilter
	);
	const unassignedConversationFilter = useReactiveVar(
		$unassignedConversationFilter
	);
	const assignedToTeamsConversationFilter = useReactiveVar(
		$assignedToTeamsConversationFilter
	);

	const unreadConversationFilter = useReactiveVar($unreadConversationFilter)

	useEffect(() => {
		if (unreadInboxData) {
		const amount = unreadInboxData.msgbox_UserInbox.reduce((total, inbox) => {
			if(!inbox.Inbox) return total
			let unread = inbox.Inbox.Folders.flatMap((folder) => {
				return folder.Conversations.flatMap((conversation) => {
					
					if(unreadConversationFilter) return [true]

					if (!assignedToMeConversationFilter && 
						!unassignedConversationFilter && 
						!assignedToTeamsConversationFilter) {
						return [true];
					}
		
					let assignedToMe = assignedToMeConversationFilter &&
									  conversation.AssignedTo === assignedToMeConversationFilter;
				
					let unassigned = unassignedConversationFilter &&
									 conversation.AssignedTo == null &&
									 conversation.AssignedToDepartment == null;
				
					let assignedToTeams = assignedToTeamsConversationFilter && assignedToTeamsConversationFilter.length > 0 &&
										  assignedToTeamsConversationFilter.find((e) => e.department == conversation.AssignedToDepartment);
				
					if (assignedToMe || unassigned || assignedToTeams) {
						return [true];
					}
				
					return [false];
				})
			})
			let count =  unread.filter(val => val).length;
			return count + total
		}, 0)
		setBadgeNumber(amount);
	}
	}, [unreadInboxData, unassignedConversationFilter, assignedToTeamsConversationFilter, assignedToMeConversationFilter, unreadConversationFilter])

	return (
		<Container>
			<AlertsButton
				data-tip="Alerts"
				onClick={handleAlertsClick}
				icon="fas fa-bell"
				data-for={"1"}
			/>
			{badgeNumber > 0 ? (
				<span>{badgeNumber}</span>
			) : null}
		</Container>
	);
};

export default Alerts;
