import { useState, useRef } from "react";
import { GET_DEPARTMENTS, DELETE_TEAM } from "./query";
import MsgBoxTable from "../MsgBoxTable/msg-box-table.component";
import Pill from "../Pill/pill.component";
import AddTeam from "./add-team.component";
import EditTeam from "./edit-team.component";
import { useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";

const TeamManagement = () => {
	const tableRef = useRef()
	const columns = [
		{
			name: "Team Name",
			selector: "name",
		},
		{
			name: "Inboxes",
			selector: "inboxes",
			cell: (row) => {
				if (row.inboxes.length > 0) {
					return row.inboxes.filter((item) => item.Inbox).map(({ Inbox }, index) => (
						<Pill
							key={index}
							asTag={false}
							name={Inbox?.Name}
						/>
					));
				} else {
					return "All Inboxes";
				}
			},
		},
		{
			name: "Team Members",
			selector: "members",
			cell: (row) => {
				if (row.members.length > 0) {
					return row.members.map(({ User }, index) => (
						<Pill
							key={index}
							asTag={false}
							name={User.FirstName + " " + User.LastName}
						/>
					));
				} else {
					return "No team members";
				}
			},
		},
	];

	const [deleteTeam, { loading, error, data }] = useMutation(DELETE_TEAM);

	const [addTeamModalOpen, setAddTeamModalOpen] = useState(false);
	const [editTeamModalOpen, setEditTeamModalOpen] = useState(false);
	const [selectedTeams, setSelectedTeams] = useState([]);
	const [filters, setFilters] = useState(null);

	const { addToast } = useToasts();

	const handleRowChange = (rows) => {
		setSelectedTeams(rows);
	};

	const openAddTeamModal = () => setAddTeamModalOpen(true);

	const closeAddTeamModal = () => {
		tableRef.current.refetchData()
		setAddTeamModalOpen(false)
	};

	const openEditTeamModal = () => setEditTeamModalOpen(true);

	const closeEditTeamModal = () => {
		tableRef.current.refetchData()
		setEditTeamModalOpen(false)
	};

	const formatData = (data) => {
		const departments = data.msgbox_Department.map((department) => ({
			id: department.DepartmentId,
			name: department.Name,
			members: department.UserDepartments,
			inboxes: department.DepartmentInboxes
		}));
		return [departments, data.msgbox_Department_aggregate.aggregate.count];
	};

	const deleteDepartments = async (rows) => {
		// Only allow the user to delete teams that have no users
		let allowDelete = true;
		for (let i = 0; i < rows.length; i++) {
			if (rows[i].members.length > 0) {
				allowDelete = false;
				break;
			}
		}
		if (allowDelete === false) {
			window.alert(
				"Unable to delete teams. You can only delete teams that have no users. Unselect the teams that have users and try again"
			);
		} else {
			const promises = rows.map((row) => {
				deleteTeam({
					variables: {
						departmentId: row.id,
					},
					update: (cache, data) => {
						cache.modify({
							fields: {
								msgbox_Department(existingResponses = []) {
									const newResponseRef = cache.writeQuery({
										data: data.data
											.update_msgbox_Department,
										query: GET_DEPARTMENTS,
									});
									return [
										...existingResponses,
										newResponseRef,
									];
								},
							},
						});
					},
				});
			});
			await Promise.all(promises);
			addToast(`Successfully deleted`, {
				appearance: "success",
				autoDismiss: true,
			});
		}
	};

	const handleSearchChange = (searchTerm) => {
		setFilters({ searchTerm: "%" + searchTerm + "%" });
	};

	return (
		<div>
			<MsgBoxTable
				defaultOrderBy={{
					field: "Name",
					sortDirection: "asc",
				}}
				ref={tableRef}
				title="Teams"
				columns={columns}
				fetchRows={GET_DEPARTMENTS}
				filters={filters}
				formatData={formatData}
				selectedRowsChange={handleRowChange}
				openModal={openAddTeamModal}
				deleteRows={deleteDepartments}
				handleSearchChange={handleSearchChange}
				editRows={openEditTeamModal}
				defaultLimit={10}
				selectableRows
				paginationServer
				pagination
				hasEdit
			/>
			<AddTeam isOpen={addTeamModalOpen} close={closeAddTeamModal} />
			<EditTeam
				selectedTeam={selectedTeams}
				isOpen={editTeamModalOpen}
				close={closeEditTeamModal}
			/>
		</div>
	);
};

export default TeamManagement;
