import { useState, useContext } from "react";
import SendTemplateMessage from "../SendTemplateMessage/send-template-message.component";
import { GET_CONTACT_IN_CONVERSATION, GET_API_ACCOUNT_ID } from "./query";
import { useQuery, useReactiveVar } from "@apollo/client";
import { Container } from "./styles";
import { ModalButton } from "../MsgBoxModal/styles";
import { $selectedConversation, $selectedInbox } from "../../store";
import { UserContext } from "../../Providers/user-provider";
import QuickSend from "../QuickSend/quick-send";

const disabledFields = {
	searchBy: true,
	contacts: true,
	tags: true,
	templateMessages: false,
	account: true,
};

const SendTemplateAfter24Hours = () => {
	const selectedConversation = useReactiveVar($selectedConversation);
	const selectedInbox = useReactiveVar($selectedInbox);
	const user = useContext(UserContext);

	const {
		loading: accountLoading,
		error: accountError,
		data: accountData,
	} = useQuery(GET_API_ACCOUNT_ID, {
		variables: {
			inboxId: selectedInbox,
		},
	});

	const {
		loading: contactLoading,
		error: contactError,
		data: contactData,
	} = useQuery(GET_CONTACT_IN_CONVERSATION, {
		variables: {
			convId: selectedConversation ? selectedConversation : null,
		},
	});

	const [modalIsOpen, setModalOpen] = useState(false);

	const openModal = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	if (contactError || accountError) return null;

	if (contactLoading || accountLoading) return null;

	if (!selectedConversation || selectedConversation === -1) return null;

	const contact = [
		{
			label: contactData?.msgbox_Contact[0]?.Name,
			value: contactData?.msgbox_Contact[0]?.ContactId,
		},
	];

	let account = accountData.msgbox_Inbox[0].APIAccountId;
	if (account === null) {
		account = user.orgId;
	}

	return (
		<Container>
			<p className="twenty-four-hour-message">
				24 hours has passed since the last inbound message. To get back
				in contact with this person click the button below to send them
				a notification.
			</p>

			<div style={{ display: "flex", gap: "6px" }}>
				<div
					style={{
						width: user.quickSendConfig[selectedInbox]
							? "66%"
							: "100%",
					}}
				>
					<ModalButton
						hasTooltip
						toolTipText="Send a notification in this chat"
						style={{ width: "100%" }}
						buttonName="Send Notification"
						onClick={openModal}
					/>
				</div>
				{user.quickSendConfig[selectedInbox] && (
					<div style={{ width: "33%" }}>
						<QuickSend account={account} />
					</div>
				)}
			</div>
			<SendTemplateMessage
				showPendingMessage={true}
				disabledFields={disabledFields}
				close={closeModal}
				isOpen={modalIsOpen}
				contacts={contact}
				account={account}
			/>
		</Container>
	);
};

export default SendTemplateAfter24Hours;
