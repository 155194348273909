import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	SEND_TEMPLATE_MESSAGE,
	GET_CONTACTS_WITH_TAG,
	CREATE_NEW_CONTACTS,
	GET_CONVERSATIONS_IDS,
	GET_LATEST_INBOUND_MESSAGES,
	SEND_NOT_DEFERRED_MESSAGE,
} from "./query";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import Stepper from "../Stepper/stepper.component";
import Step1 from "./send-template-message-step1.component";
import Step2 from "./send-template-message-step2.component";
import client from "../../Apollo";
import { useToasts } from "react-toast-notifications";
import { getAccessToken } from "../../helpers/functions";
import Modal from "react-modal";
import {
	ModalDescription,
	ModalHeader,
	ModalContentSeperator,
} from "../MsgBoxModal/styles";
import ReactGA from "react-ga4";
import useCurrentUserDetails from "../../helpers/use-current-user-details";
import { generateValidationScheme } from "./valiadtaion-schema";
import { $isMessageSending } from "../../store";

const SendTemplateMessage = ({
	isOpen,
	close,
	disableSearchBy,
	contacts,
	disabledFields,
	account,
	table,
	showPendingMessage = false,
}) => {
	const { currentUser } = useCurrentUserDetails();
	const [addContacts] = useMutation(CREATE_NEW_CONTACTS);
	const [sendMessage, { error }] = useMutation(SEND_TEMPLATE_MESSAGE);
	const [sendNotDeferredMessage] = useMutation(SEND_NOT_DEFERRED_MESSAGE);
	const [naturalChatTemplate, setNaturalChatTemplate] = useState(null);
	const [newNaturalChatTemplate, setNewNaturalChatTemplate] = useState(null);

	const [currentStep, setCurrentStep] = useState(1);
	const { addToast } = useToasts();

	const sendOwnMessage = async (values, actions, recipients) => {
		try {
			if (newNaturalChatTemplate) {
				const templateText = newNaturalChatTemplate.TemplateText;
				const templateMessage = templateText.replace(
					"{{1}}",
					values.message
				);

				await sendMessage({
					variables: {
						attachmentId: null,
						TemplateId: newNaturalChatTemplate.TemplateId,
						Message: templateMessage,
						account: disabledFields.account
							? account
							: values.account.value,
						contacts: {
							data: recipients.map((x) => {
								return {
									ContactId: x.ContactId,
								};
							}),
						},
					},
				});
			} else {
				const templateText = naturalChatTemplate.TemplateText;

				const msg = templateText.replace(
					"{{1}}",
					currentUser.FirstName
				);
				// get conversations for contacts
				const { data: conversationIdsData } = await client.query({
					query: GET_CONVERSATIONS_IDS,
					variables: {
						contactIds: recipients.map((rec) => rec.ContactId),
					},
				});

				const contactsWithoutConversations = recipients.filter(
					(recipient) =>
						!conversationIdsData.msgbox_Conversation
							.map((x) => x.ContactId)
							.includes(recipient.ContactId)
				);
				if (contactsWithoutConversations.length > 0) {
					await sendMessage({
						variables: {
							attachmentId: null,
							TemplateId: naturalChatTemplate.TemplateId,
							Message: msg,
							account: disabledFields.account
								? account
								: values.account.value,
							contacts: {
								data: contactsWithoutConversations,
							},
							followOnMessage: values.message,
						},
					});
				}
				if (conversationIdsData.msgbox_Conversation.length > 0) {
					let tempDate = new Date();
					tempDate.setDate(tempDate.getDate() - 1);
					const { data: getLatestInboundMessage } =
						await client.query({
							query: GET_LATEST_INBOUND_MESSAGES,
							variables: {
								ids: conversationIdsData.msgbox_Conversation.map(
									(x) => x.ConversationId
								),
								_gt: tempDate,
							},
						});

					// conversations inside 24h window
					if (getLatestInboundMessage.msgbox_Message.length > 0) {
						const promises =
							getLatestInboundMessage.msgbox_Message.map((x) => {
								sendNotDeferredMessage({
									variables: {
										Message: values.message,
										conversationId: x.ConversationId,
									},
								});
							});
						await Promise.all(promises);
					}

					const conversationsOutside24h =
						conversationIdsData.msgbox_Conversation.filter(
							(convData) =>
								!getLatestInboundMessage.msgbox_Message
									.map((x) => x.ConversationId)
									.includes(convData.ConversationId)
						);
					if (conversationsOutside24h.length > 0) {
						await sendMessage({
							variables: {
								attachmentId: null,
								TemplateId: naturalChatTemplate.TemplateId,
								Message: msg,
								account: disabledFields.account
									? account
									: values.account.value,
								contacts: {
									data: conversationsOutside24h.map((x) => {
										return {
											ContactId: x.ContactId,
										};
									}),
								},
								followOnMessage: values.message,
							},
						});
					}
				}
			}

			actions.resetForm();
			close();
			addToast("Messages sent successfully", {
				appearance: "success",
				autoDismiss: true,
			});
			if (table) {
				table.current.refetchData();
			}
			return;
		} catch (e) {
			console.log("errro", error);
			addToast("Error sending messages", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	const onSubmit = async (values, actions) => {

		let addedContactsArray = [];
		const contactsToBeAdded = values.contacts.filter(
			(x) => x.status === "NEW_CONTACT"
		);
		if (contactsToBeAdded.length > 0) {
			const { data: addedContacts } = await addContacts({
				variables: {
					objects: contactsToBeAdded.map((x) => {
						return {
							Name: x.value.trim().replace(" ", ""),
							MobileNumber: x.value.trim().replace(" ", ""),
							Status: "Presubscribed",
							APIAccountId: values.account.value,
						};
					}),
				},
			});

			addedContactsArray =
				addedContacts.insert_msgbox_Contact.returning.map((x) => {
					return { ContactId: x.ContactId };
				});
		}
		const alreadyExistingContacts = values.contacts.filter(
			(x) => x.status !== "NEW_CONTACT"
		);
		let recipients = [];
		let attachmentId = null;
		// using form with ability to select contacts
		if (contacts.length <= 0) {
			if (values.searchBy === "search-contacts") {
				// already existing and new contacts here
				recipients = alreadyExistingContacts.map((contact) => ({
					ContactId: contact.value,
				}));
				if (addedContactsArray.length > 0) {
					recipients = [...recipients, ...addedContactsArray];
				}
			} else {
				// send message based on the contacts tag
				// Fetch all contacts that have selected tags.
				const tags = values.tags.map((tag) =>
					tag.label.replace(/ *\([^)]*\) */g, "")
				);
				const contactsWithTags = await client.query({
					query: GET_CONTACTS_WITH_TAG,
					variables: {
						tags,
						account: values.account.value,
					},
				});


				let unSubscribed = 0
				recipients = contactsWithTags.data.msgbox_Contact.map((contact) => {
					if(contact.Status !== "Subscribed") unSubscribed++
                    if(contact.Status !== "Subscribed" && unSubscribed > 10) return null
					return {
						ContactId: contact.ContactId,
					}
				}).filter((item) => item)	
				
			}
		} else {
			recipients = contacts.map((contact) => ({
				ContactId: contact.value,
			}));
		}
		if (values.message && values.message.length > 0) {
			if (!/\n/.test(values.message) && (naturalChatTemplate || newNaturalChatTemplate)) {
				await sendOwnMessage(values, actions, recipients);
			}
			return;
		}
		if (values.attachments.length > 0) {
			attachmentId = await uploadAttachment(values.attachments[0]);
			if(!attachmentId) return
		}
		let message = values.templateMessages.value.text;
		if (values.variables.length > 0) {
			message = values.templateMessages.value.text;
			values.variables.forEach((variable) => {
				if (
					variable.value === "contactname" ||
					variable.value === "saluteas" ||
					variable.value === "firstname" ||
					variable.value === "lastname" ||
					variable.value === "orgname" ||
					variable.value === "inboxname" ||
					variable.value === "fullname"
				) {
					message = message.replace(
						`{{${variable.label}}}`,
						`{{${variable.value}}}`
					);
				} else {
					message = message.replace(
						`{{${variable.label}}}`,
						variable.value
					);
				}
			});
		}
		await sendMessage({
			variables: {
				attachmentId,
				TemplateId: values.templateMessages.value.templateId,
				Message: message,
				// If the account field is disabled / hidden use the account prop
				account: disabledFields.account
					? account
					: values.account.value,
				contacts: {
					data: recipients,
				},
			},
		});

		if (!error) {
			actions.resetForm();
			close();
			addToast("Templates sent successfully", {
				appearance: "success",
				autoDismiss: true,
			});
			if (showPendingMessage) {
				$isMessageSending(true);
			}
			if (table) {
				table.current.refetchData();
			}
			ReactGA.event({
				category: "Messages",
				action: "SentTemplateMessage",
			});
		} else {
			console.log("errro", error);
			addToast("Error sending messages", {
				appearance: "error",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Messages",
				action: "FailedSendTemplateMessage",
			});
		}
	};

	const uploadAttachment = async (file) => {
		const { name, type } = file;
		try {
			const token = await getAccessToken();

			const signedUrlResponse = await fetch(`${process.env.REACT_APP_API_URL}filessignedurl`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": type,
					},
				}
			);

			const { attachmentId, presignedUrl } = await signedUrlResponse.json();

			const attachmentResponse = await fetch(presignedUrl, {
				method: "PUT",
				headers: {
					"Content-Disposition": `filename=${encodeURIComponent(name)}`,
					"Content-Type": type,
				},
				body: file,
			});

			if (
				attachmentResponse.status === 200 ||
				attachmentResponse.status === 201
			) {
				return attachmentId;
			} else {
				return false
			}
		} catch (error) {
			console.log("error", error);
		}
	};

	/**
	 * Determines if we need to render step 2 - if there are no variables or attachments dont render step 2
	 */
	const getSteps = (values) => {
		let steps = [
			{
				title: "Filters",
				component: (
					<Step1
						disabledFields={disabledFields}
						disableSearchBy={disableSearchBy}
						apiAccountIdProp={account}
						setNaturalChatTemplate={setNaturalChatTemplate}
						naturalChatTemplate={naturalChatTemplate}
						setNewNaturalChatTemplate={setNewNaturalChatTemplate}
						newNaturalChatTemplate={newNaturalChatTemplate}
					/>
				),
			},
			{
				title: "Variables",
				component: <Step2 disabledFields={disabledFields} />,
			},
		];
		// If there is an attachment => render step 2
		if (
			!values.templateMessages.value?.attachment ||
			values.templateMessages.value?.attachment === "None"
		) {
			// If there is no attachemnt, check if there is variable fields.
			const regex = /[^{{\}]+(?=}})/g;
			const results = values.templateMessages.value?.text.match(regex);
			if (!results) {
				steps.splice(1, 1);
			}
		}

		return steps;
	};

	const handleStepChange = (currentStep) => {
		setCurrentStep(currentStep);
	};

	const renderTitle = () => {
		switch (currentStep) {
			case 1:
				return "Send Template Message";

			case 2:
				return "Variables ";

			default:
				break;
		}
	};

	const renderDescription = () => {
		switch (currentStep) {
			case 1:
				return "Use the form below to send a template message.";

			case 2:
				return "Enter the necessery variable values once you are happy click submit to send.";
			default:
				break;
		}
	};

	return (
		<Formik
			validationSchema={
				generateValidationScheme(
					disabledFields,
					naturalChatTemplate,
					newNaturalChatTemplate
				)[currentStep - 1]
			}
			validateOnBlur={false}
			validateOnChange={false}
			initialValues={{
				tags: [],
				contacts: contacts,
				templateMessages: "",
				variables: [],
				attachments: [],
				templateMessage: "",
				searchBy: "search-contacts",
				account: account,
				message: "",
			}}
			onSubmit={(values, actions) => {
				onSubmit(values, actions);
			}}
		>
			{({ handleSubmit, values, validateForm, resetForm }) => (
				<Modal
					aria={{
						labelledby: "add_template_message_heading",
						describedby: "add_template_message_full_description",
					}}
					isOpen={isOpen}
					onRequestClose={() => {
						close();
						resetForm();
					}}
					style={{
						content: {
							overflow: "visible",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						},
						overlay: {
							backgroundColor: "rgba(0,0,0,0.7)",
						},
					}}
				>
					<form id="send_template_message" onSubmit={handleSubmit}>
						<ModalHeader>{renderTitle()}</ModalHeader>
						<ModalDescription>
							{renderDescription()}
						</ModalDescription>
						<ModalContentSeperator />
						<Stepper
							validate={validateForm}
							onStepChange={handleStepChange}
							steps={getSteps(values)}
							closeModal={() => {
								close();
								resetForm();
							}}
							hasMessage={values.message.length > 0}
						/>
					</form>
				</Modal>
			)}
		</Formik>
	);
};

SendTemplateMessage.defaultProps = {
	disableSearchBy: false,
	contacts: [],
	disabledFields: {
		searchBy: false,
		contacts: false,
		tags: false,
		templateMessages: false,
		account: false,
	},
	table: null,
};

SendTemplateMessage.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	/**
	 * Removes the ability to change the "search by" field. Needed because in the contacts page we prepopulate contacts.
	 */
	disableSearchBy: PropTypes.bool,
	contacts: PropTypes.array,
	disabledFields: PropTypes.object,
};

export default SendTemplateMessage;
