import { gql } from "@apollo/client";

export const GET_RESPONSES = gql`
	query getResponses(
		$limit: Int
		$offset: Int
		$order_by: msgbox_BotResponse_order_by!
		$searchTerm: String
		$apiAccounts: [Int!]
	) {
		msgbox_BotResponse(
			limit: $limit
			offset: $offset
			order_by: [$order_by]
			where: {
				Keyword: { _ilike: $searchTerm }
				_and: { Keyword: { _is_null: false } }
				APIAccount: { APIAccountId: { _in: $apiAccounts } }
			}
		) {
			Reply
			RouteTo
			ListMetadata
      		ReplyMetadata
			Keyword
			IsEnabled
			BotResponseId
			IsDeleted
			IsDeletable
			AssignTo
			RequiresResponse
			CaptureEmailAddress
			BotResponseType
			APIAccount {
				APIAccountId
				Name
			}
			Folder {
				Name
				FolderId
				Inbox {
					Name
				}
			}
			AssignToDepartment
			Department {
				Name
				DepartmentId
			}
			userByAssignto {
				FirstName
				LastName
				UserId
				UserDepartments {
					Department {
						Name
						DepartmentId
					}
				}
			}
		}
		msgbox_BotResponse_aggregate(
			where: {
				Keyword: { _ilike: $searchTerm }
				_and: { Keyword: { _is_null: false } }
				APIAccount: { APIAccountId: { _in: $apiAccounts } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_RESPONSE_KEYWORDS = gql`
query getResponses(
		$apiAccounts: [Int!]
	) {
		msgbox_BotResponse(
			where: {
				APIAccount: { APIAccountId: { _in: $apiAccounts } }
			}
		) {
			BotResponseId
			Keyword
			APIAccount {
				APIAccountId
			}
		}
	}
`;

export const GET_ASSIGNEES = gql`
	query getAssignees {
		msgbox_Organisation {
			Name
			Departments {
				Name
				DepartmentId
				Users {
					UserId
					FirstName
					LastName
				}
			}
		}
	}
`;

export const GET_INBOXES = gql`
	query getInboxes {
		msgbox_Inbox {
			Name
			APIAccount {
				Name
			}
			Folders (order_by: {FolderId: asc}){
				FolderId
				InboxId
				Name
			}
			InboxId
		}
	}
`;

export const ADD_AUTOMATION = gql`
	mutation addAutomation(
		$keyword: String!
		$reply: String!
		$assignTo: Int
		$routeTo: Int
		$account: Int!
		$assignToDepartment: Int
		$matchType: String!
		$isEnabled: Boolean!
		$ListMetaData: String
		$ReplyMetaData: String
	) {
		insert_msgbox_BotResponse_one(
			object: {
				Keyword: $keyword
				Reply: $reply
				AssignTo: $assignTo
				RouteTo: $routeTo
				APIAccountId: $account
				AssignToDepartment: $assignToDepartment
				BotResponseType: $matchType
				IsEnabled: $isEnabled
				ListMetadata: $ListMetaData
				ReplyMetadata: $ReplyMetaData
			}
		) {
			Keyword
			BotResponseId
		}
	}
`;

export const DELETE_AUTOMATION = gql`
mutation deleteAutomation($responseId: Int!) {
	update_msgbox_BotResponse(where: {BotResponseId: {_eq: $responseId}}, _set: {IsDeleted: true, DeletedOn: "now()"}) {
	  affected_rows
	  returning {
		BotResponseId
	  }
	}
  }
`;

export const EDIT_AUTOMATION = gql`
	mutation editAutomation(
		$responseId: Int!
		$keyword: String!
		$reply: String!
		$assignee: Int
		$routeTo: Int
		$assignToDepartment: Int
		$matchType: String
		$isEnabled: Boolean
		$ListMetaData: String
		$ReplyMetaData: String
	) {
		update_msgbox_BotResponse(
			where: { BotResponseId: { _eq: $responseId } }
			_set: {
				Reply: $reply
				AssignTo: $assignee
				RouteTo: $routeTo
				Keyword: $keyword
				AssignToDepartment: $assignToDepartment
				BotResponseType: $matchType
				IsEnabled: $isEnabled
				ListMetadata: $ListMetaData
				ReplyMetadata: $ReplyMetaData
			}
		) {
			affected_rows
		}
	}
`;

// Assignees
export const GET_USERS = gql`
	query getUsers {
		msgbox_Organisation {
			Name
			Departments {
				DepartmentInboxes {
					InboxId
				}
				Name
				DepartmentId
				UserDepartments {
					User {
						UserId
						LastName
						FirstName
					}
				}
			}
		}
	}
`;

export const GET_ALL_USERS = gql`
	query getAllUsers {
		msgbox_User(where: { IsBot: { _eq: false } }) {
			LastName
			UserId
			FirstName
			UserDepartments {
				Department {
					Name
					DepartmentId
				}
			}
		}
	}
`;

export const DELETE_CHILD_RESPONSES = gql`
	mutation deleteChildResponses($parentId: Int!) {
		update_msgbox_BotResponse(
			where: { ParentBotReplyId: { _eq: $parentId } }
			_set: { IsDeleted: true }
		) {
			affected_rows
			returning {
				BotResponseId
				IsDeleted
			}
		}
	}
`;
