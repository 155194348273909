import { useEffect, useState } from "react";
import { Folders } from "./styles";
import {
	$selectedFolder,
	$selectedConversation,
	$selectedInbox,
	$userInboxes,
	$conversationFolderFilter
} from "../../store";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_FOLDERS_IN_INBOX } from "./query";

const FolderList = () => {
	const selectedInbox = useReactiveVar($selectedInbox);
	const userInboxes = useReactiveVar($userInboxes)

	const selectedFolder = useReactiveVar($selectedFolder);
	const conversationFolderFilter = useReactiveVar($conversationFolderFilter)
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);

	const { data, loading, error, refetch } = useQuery(GET_FOLDERS_IN_INBOX, {
		variables: {
			inboxes: userInboxes
		},
	});

	useEffect(() => {
		console.log("refetching")
		refetch()
	}, [userInboxes])

	useEffect(() => {
		if (data) {
			const options = data.msgbox_Folder.map(folder => ({
					label: folder.Name,
					value: folder.FolderId,
					isArchive: folder.IsArchive,
					isSystemFolder: folder.IsSystemFolder,
				}));
			if(options.length == 0) return
			setOptions(options);
			if (selectedFolder === -1 || !selectedFolder) {
				//console.log("setting folderid to:", options[0]?.value);
				setSelectedOption(options[0]);
				$selectedFolder(options[0]?.value);
				$conversationFolderFilter(options[0]?.label)
			} else {
				// Selected folder has been set externally. Find the option and set the dropdown to match
				if (selectedFolder && !selectedOption) {
					const option = options.find(
						(option) => option.value === selectedFolder
					);
					setSelectedOption(option);
					$conversationFolderFilter(option.label)
				}
			}
		}
	}, [data, selectedInbox]);

	const handleFolderChange = (selectedOption) => {
		if (selectedOption.value !== selectedFolder) {
			//console.log("wiping out conversationd, setting folderid to:", selectedOption.value)
			$selectedFolder(selectedOption.value);
			$selectedConversation(null);
			setSelectedOption(selectedOption);
			$conversationFolderFilter(selectedOption.label)
		}
	};

	if (error) return null;

	if (loading) return null;

	return (
		<Folders
			form={false}
			value={selectedOption}
			onChange={handleFolderChange}
			options={options.filter((folder, index, self) => 
				index === self.findIndex((f) => f.label === folder.label)
			)}
			hasLabel={false}
		/>
	);
};

export default FolderList;
