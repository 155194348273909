import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
	StyledNavBar,
	StyledNavLink,
	Icon,
	Logo,
	Circle,
	MobileLogo,
	LogoutIcon,
} from "./styles";
import { UserContext } from "../../Providers/user-provider";
import { useTheme } from "styled-components";
import { useQuery, useReactiveVar } from "@apollo/client";
import Backdrop from "../Backdrop/back-drop.component";
import {
	removeAccessToken,
	removeIdToken,
	removeTokenExpiry,
	removeUserId,
} from "../../helpers/functions";
import ReactTooltip from "react-tooltip";
import { GET_ALL_USER_INBOXES } from "./query";
import useWebSocket from "react-use-websocket";
import {
	$selectedFolder,
	$selectedConversation,
	$selectedInbox,
	$unreadInboxData,
	$conversationFolderFilter,
	$reuseableDialog,
} from "../../store";
import client from "../../Apollo";

import ReactGA from "react-ga4";
import { useToasts } from "react-toast-notifications";

const Navbar = (props) => {
	const { addToast } = useToasts();

	const theme = useTheme();
	const userContext = useContext(UserContext);
	const { loading, error, data, refetch } = useQuery(GET_ALL_USER_INBOXES, {
		variables: {
			userId: localStorage.getItem("user_id"),
		},
	});

	const history = useHistory();
	const selectedInbox = useReactiveVar($selectedInbox);
	//const unreadInboxData = useReactiveVar($unreadInboxData);
	const user = useContext(UserContext);

	useEffect(() => {
		$unreadInboxData(data);
	}, [data]);

	const { lastMessage } = useWebSocket(
		`${process.env.REACT_APP_WSAPI_URL}?orgid=${userContext.orgId}&token=${localStorage.getItem("access_token")}`,
		{
			reconnectAttempts: 100,
			reconnectInterval: (attemptNumber) =>
				Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
			share: true,
			shouldReconnect: (closeEvent) => true,
			onError: handleConnectionFailure,
		}
	);

	function handleConnectionFailure() {
		$reuseableDialog({
			visible: true,
			title: "Lost connection to the server",
			body: "You must refresh the page or no new messages will show",
			onPress: () => window.location.reload(),
			button: "Refresh",
		})
	}


	useEffect(() => {
		if (lastMessage && lastMessage.data ) {
			const incomingData = JSON.parse(lastMessage.data);
			if(incomingData.type == "conversation_update") refetch();
		}
	}, [lastMessage]);

	const logout = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}oauth/token`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
					credentials: "include",
				}
			);
			client.clearStore();
			removeAccessToken();
			removeIdToken();
			removeTokenExpiry();
			removeUserId();
			ReactGA.event({
				category: "Authentication",
				action: "SuccessfulLogout",
			});
			history.push("/login");
		} catch (error) {
			console.log("err", error);
		}
	};
	/*
	useEffect(() => {
		subscribeToMore({
			document: SUBSCRIBE_TO_MORE_INBOXES,
			variables: {
				userId: localStorage.getItem("user_id"),
			},
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				return subscriptionData.data;
			},
		});
	}, []);
	*/
	if (!loading) {
		return (
			<>
				<Backdrop onClick={props.close} visible={props.visible} />
				<StyledNavBar {...props} id="side-nav">
					{theme.images.topleftIcon !== undefined ? (
						<Logo className="sidebar-brand-text">
							<img
								style={{ width: "35px", height: "35px" }}
								src={theme.images.topleftIcon}
							></img>
						</Logo>
					) : (
						<div
							style={{
								height: "50px",
								width: "40px",
								clear: "both",
							}}
						></div>
					)}
					<MobileLogo className="sidebar-brand-text">
						<Circle></Circle>
						MSGBOX
					</MobileLogo>
					<ul>
						{(data && data.msgbox_UserInbox.length <
						process.env.REACT_APP_MAXINBOXES) ? (
							data.msgbox_UserInbox.map((inbox, index) => {
								const unreadMessages =
									inbox.Inbox.Folders.reduce(
										(prev, current) => {
											return (
												prev +
												current.Conversations.length
											);
										},
										0
									);
								return (
									<li
										key={index}
										onClick={() => {
											if (
												selectedInbox !==
												inbox.Inbox.InboxId
											) {
												$selectedConversation(-1);
												$selectedFolder(-1);
												$conversationFolderFilter("Inbox")
												$selectedInbox(
													inbox.Inbox.InboxId
												);
											}
										}}
									>
										<StyledNavLink
											className={
												selectedInbox ===
												inbox.Inbox.InboxId
													? "selected"
													: ""
											}
											to={`/inbox`}
											data-tip={inbox.Inbox.Name}
											data-for="2"
										>
											<Icon className="fas fa-envelope"></Icon>
											{unreadMessages > 0 ? (
												<small>{unreadMessages}</small>
											) : null}
										</StyledNavLink>
									</li>
								);
							})
						) : (
							<li
								key="1"
								onClick={() => {
									console.log(2,data?.msgbox_UserInbox[0]?.Inbox?.Folders[0]?.Name, data?.msgbox_UserInbox[0]?.Inbox?.Folders[0]?.FolderId, data?.msgbox_UserInbox[0]?.Inbox?.InboxId)
									localStorage.setItem('selected_inbox', data?.msgbox_UserInbox[0]?.Inbox?.InboxId || 0)
									$selectedConversation(-1);
									$selectedFolder(data?.msgbox_UserInbox[0]?.Inbox?.Folders[0]?.FolderId || -1);
									$conversationFolderFilter(data?.msgbox_UserInbox[0]?.Inbox?.Folders[0]?.Name)
									$selectedInbox(data?.msgbox_UserInbox[0]?.Inbox?.InboxId || 0)
								}}
							>
								<StyledNavLink
									activeClassName="selected"
									to="/inbox"
									data-tip="Inboxes"
									data-for="2"
								>
									<Icon className="fas fa-envelope"></Icon>
								</StyledNavLink>
							</li>
						)}

						<li data-tip="Contacts" data-for="2">
							<StyledNavLink
								to="/contacts"
								activeClassName="selected"
							>
								<Icon className="fas fa-address-book"></Icon>
							</StyledNavLink>
						</li>
						<li
							data-for="2"
							data-tip="Broadcast"
							onClick={props.close}
						>
							<StyledNavLink
								to="/broadcast"
								activeClassName="selected"
							>
								<Icon className="fas fa-podcast"></Icon>
							</StyledNavLink>
						</li>

						{user.permissions.canViewAutomations && (
							<li
								data-for="2"
								data-tip="Automations"
								onClick={props.close}
							>
								<StyledNavLink
									to="/automations"
									activeClassName="selected"
								>
									<Icon className="fas fa-lightbulb"></Icon>
								</StyledNavLink>
							</li>
						)}
						{user.permissions.canViewAnalytics && (
							<li
								data-for="2"
								data-tip="Analytics"
								onClick={props.close}
							>
								<StyledNavLink
									to="/analytics"
									activeClassName="selected"
								>
									<Icon className="fas fa-chart-line"></Icon>
								</StyledNavLink>
							</li>
						)}
					</ul>
					<LogoutIcon
						onClick={logout}
						className="fas fa-sign-out-alt"
						data-tip="Logout"
						data-for="2"
					/>
				</StyledNavBar>
				<ReactTooltip id="2" effect="solid" className="tooltip" />
			</>
		);
	} else return null;
};

export default Navbar;
