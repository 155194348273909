import React, { useState } from "react";
import {
	Container,
	AnalyticsFiltersContainer,
	DataContainer,
	ChartContainer,
	Header,
} from "./styles";
import MsgBoxDatePicker from "../../Components/DatePicker/date-picker.component";
import DataCard from "./data-card";
import {
	GET_BOT_RESPONSES,
	GET_CONTACTS_DATA,
	GET_CONVERSATIONS_DATA,
	GET_INBOUND_MESSAGE_DATA,
	GET_OUTBOUND_MESSAGE_DATA,
	GET_SENT_NOTIFICATION_DATA,
} from "./query";
import { useQuery } from "@apollo/client";
import {
	CONTACTS,
	TEMPLATES,
	INBOUND_MESSAGES,
	OUTBOUND_MESSAGES,
	CONVERSATIONS,
	BOT_RESPONSE,
} from "./helper";
import { startOfMonth } from "date-fns";
// Charts
import InboundMessagesChart from "./Charts/inbound-messages-chart.js";
import ContactsChart from "./Charts/contacts-chart";
import ConversationsChart from "./Charts/conversations-chart";
import OutboundMessagesChart from "./Charts/outbound-messages-chart";
import SentTemplatesChart from "./Charts/templates-chart";
import ContactsBarchart from "./Charts/contacts-barchart";
import TemplatesBarchart from "./Charts/templates-barchart";
import ReactGA from "react-ga4";
import TemplatesTable from "./Charts/templates-table";
import OutboundMessagesTable from "./Charts/outbound-messages-table";
import InboxSelect from "./inbox-select.component";
import BotResponsesTable from "./Charts/bot-responses-table";

const AnalyticsPage = () => {
	const [selectedInboxes, setSelectedInboxes] = useState([]);
	const [dateRange, setDateRange] = useState({
		start: startOfMonth(new Date()),
		end: new Date(),
	});

	const [selectedCard, setSelectedCard] = useState(CONTACTS);

	const {
		data: contacts,
		error: contactsError,
		loading: contactsLoading,
	} = useQuery(GET_CONTACTS_DATA, {
		variables: {
			start: convertTimestampToDate(dateRange.start),
			end: convertTimestampToDate(dateRange.end),
			inboxes: selectedInboxes.value,
		},
		fetchPolicy: "network-only",

	});

	const {
		data: botResonses,
		error: botResponseError,
		loading: botResponseLoading,
	} = useQuery(GET_BOT_RESPONSES, {
		variables: {
			start: convertTimestampToDate(dateRange.start),
			end: convertTimestampToDate(dateRange.end),
			inboxes: selectedInboxes.value,
		},
		fetchPolicy: "network-only",
	});


	const {
		data: templates,
		error: templatesError,
		loading: templatesLoading,
	} = useQuery(GET_SENT_NOTIFICATION_DATA, {
		variables: {
			start: convertTimestampToDate(dateRange.start),
			end: convertTimestampToDate(dateRange.end),
			inboxes: selectedInboxes.value,
		},
		fetchPolicy: "network-only",
	});

	const {
		data: inboundMessages,
		error: inboundMessagesError,
		loading: inboundMessagesLoading,
	} = useQuery(GET_INBOUND_MESSAGE_DATA, {
		variables: {
			start: convertTimestampToDate(dateRange.start),
			end: convertTimestampToDate(dateRange.end),
			inboxes: selectedInboxes.value,
		},
		fetchPolicy: "network-only",
	});

	const {
		data: outboundMessages,
		error: outboundMessagesError,
		loading: outboundMessagesLoading,
	} = useQuery(GET_OUTBOUND_MESSAGE_DATA, {
		variables: {
			start: convertTimestampToDate(dateRange.start),
			end: convertTimestampToDate(dateRange.end),
			inboxes: selectedInboxes.value,
		},
		fetchPolicy: "network-only",

	});

	const {
		data: conversations,
		error: conversationsError,
		loading: conversationsLoading,
	} = useQuery(GET_CONVERSATIONS_DATA, {
		variables: {
			start: convertTimestampToDate(dateRange.start),
			end: convertTimestampToDate(dateRange.end),
			inboxes: selectedInboxes.value,
		},
		fetchPolicy: "network-only",
	});

	function convertTimestampToDate(ts) {
		if (ts == null) return null
		const date = new Date(ts);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
	  	return `${year}-${month}-${day}`;
	  }
	  
	ReactGA.send({ hitType: "pageview", page: "/analytics" });
	return (
		<Container>
			<Header role="heading" aria-level="1">
				Analytics
			</Header>
			<AnalyticsFiltersContainer>
				<MsgBoxDatePicker
					defaultRange={dateRange}
					onDateRangeChange={(dateRange) => setDateRange(dateRange)}
				/>
				<div style={{ marginTop: "5px", width: "50%" }}>
					<InboxSelect
						setInbox={(inbox) => setSelectedInboxes(inbox[0])}
						value={selectedInboxes}
						onChange={(inbox) => setSelectedInboxes(inbox)}
					/>
				</div>
			</AnalyticsFiltersContainer>
			 <DataContainer>
				{!contactsError && contactsLoading ? null : (
					<DataCard
						total={
							contacts?.reporting_ContactsCreatedByInbox?.reduce((acc, item) => acc + item.Count, 0)
						}
						name="Contacts"
						onClick={() => setSelectedCard(CONTACTS)}
						selected={selectedCard === CONTACTS}
					/>
				)}
				{!templatesError && templatesLoading ? null : (
					<DataCard
						total={
								templates?.reporting_TemplatesSentByInbox?.reduce((acc, item) => acc + item.Count, 0)
						}
						name="Templates Sent"
						onClick={() => setSelectedCard(TEMPLATES)}
						selected={selectedCard === TEMPLATES}
					/>
				)}
				{!inboundMessagesError && inboundMessagesLoading ? null : (
					<DataCard
						total={
								inboundMessages?.reporting_MessagesInboundByInbox?.reduce((acc, item) => acc + item.Count, 0)
						}
						name="Inbound Messages"
						onClick={() => setSelectedCard(INBOUND_MESSAGES)}
						selected={selectedCard === INBOUND_MESSAGES}
					/>
				)}
				{!outboundMessagesError && outboundMessagesLoading ? null : (
					<DataCard
						total={
						outboundMessages?.reporting_MessagesOutboundByInbox?.reduce((acc, item) => acc + item.Count, 0)
						}
						name="Outbound Messages"
						onClick={() => setSelectedCard(OUTBOUND_MESSAGES)}
						selected={selectedCard === OUTBOUND_MESSAGES}
					/>
				)}
				{!conversationsError && conversationsLoading ? null : (
					<DataCard
						total={
							conversations.reporting_ConversationsByInbox.reduce((acc, item) => acc + item.Count, 0)
						}
						name="Total Conversations"
						onClick={() => setSelectedCard(CONVERSATIONS)}
						selected={selectedCard === CONVERSATIONS}
					/>
				)}
				{!botResponseError && botResponseLoading ? null : (
					<DataCard
						total={
							botResonses.reporting_BotResponsesByInbox.reduce((acc, item) => acc + item.Count, 0)
						}
						name="Bot Responses"
						onClick={() => setSelectedCard(BOT_RESPONSE)}
						selected={selectedCard === BOT_RESPONSE}
					/>
				)}
			</DataContainer> 
			
			{selectedCard === INBOUND_MESSAGES && (
				<InboundMessagesChart
					start={dateRange.start}
					data={inboundMessages}
					end={dateRange.end}
					inboxes={selectedInboxes.value}
				/>
			)}
			 {selectedCard === CONTACTS && (
				<DataContainer>
					<ChartContainer>
						<ContactsChart
							start={dateRange.start}
							end={dateRange.end}
							data={contacts}
							inboxes={selectedInboxes.value}
						/>
					</ChartContainer>
					<ChartContainer>
						<ContactsBarchart
							start={dateRange.start}
							end={dateRange.end}
							data={contacts}
							inboxes={selectedInboxes.value}
						/>
					</ChartContainer>
				</DataContainer>
			)}
			{selectedCard === CONVERSATIONS && (
				<ConversationsChart
					start={dateRange.start}
					end={dateRange.end}
					data={conversations}

					inboxes={selectedInboxes.value}
				/>
			)}
			{selectedCard === BOT_RESPONSE && (
				<>
					<BotResponsesTable
						data={botResonses}
					/>
				</>
			)}
			{selectedCard === OUTBOUND_MESSAGES && (
				<>
					<OutboundMessagesChart
						start={dateRange.start}
						end={dateRange.end}
						inboxes={selectedInboxes.value}
						data={outboundMessages}
					/>
					<OutboundMessagesTable
						start={dateRange.start}
						end={dateRange.end}
						inboxes={selectedInboxes.value}
						data={outboundMessages}

					/>
				</>
			)}
			{selectedCard === TEMPLATES && (
				<>
					<DataContainer>
						<ChartContainer>
							<SentTemplatesChart
								start={dateRange.start}
								end={dateRange.end}
								data={templates}

								inboxes={selectedInboxes.value}
							/>
						</ChartContainer>
						<ChartContainer>
							<TemplatesBarchart
								start={dateRange.start}
								end={dateRange.end}
								data={templates}

								inboxes={selectedInboxes.value}
							/>
						</ChartContainer>
					</DataContainer>
					<TemplatesTable
						start={dateRange.start}
						end={dateRange.end}
						data={templates}

						inboxes={selectedInboxes.value}
					/>
				</>
			)} 
		</Container>
	);
};

export default AnalyticsPage;
