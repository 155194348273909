import { makeAutoObservable, runInAction, reaction } from "mobx"
import { LOAD_INBOXES } from "./inboxQueries"
import client from "../Apollo";
import { RootStore } from "./rootStore";

export class InboxStore {
    rootStore
    inboxes: Inbox[] = []
    apiAccounts: APIAccount[] = []
    folders: Folder[] = []
    isLoading = true

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    async init(userId: number) {
        //console.log("loading stuff")
        this.isLoading = true
        const response = await client.query({
            query: LOAD_INBOXES,
            variables: {
                userId,
            },
            fetchPolicy: "network-only",
        });
        if (response && response.data) {
            response.data.msgbox_UserInbox.forEach((inbox: any) => {
                var apiAccount = { apiAccountId: inbox?.Inbox?.APIAccount?.APIAccountId, name: inbox?.Inbox?.APIAccount?.Name }
                this.apiAccounts.push(apiAccount)
                var inboxFolders: Folder[] = []
                if (inbox && inbox.Inbox && inbox.Inbox.Folders) {
                    inbox.Inbox.Folders.forEach((folder: any) => {
                        inboxFolders.push({ folderId: folder.FolderId, name: folder.Name, isInbox: folder.IsInbox, isArchive: folder.IsArchive, isSpam: folder.IsSpam, inboxId: inbox.InboxId })
                        this.folders.push({ folderId: folder.FolderId, name: folder.Name, isInbox: folder.IsInbox, isArchive: folder.IsArchive, isSpam: folder.IsSpam, inboxId: inbox.InboxId })
                    })    
                    var myInbox = { inboxId: inbox.InboxId, name: inbox.Inbox.Name, apiAccount: apiAccount, apiAccountId: inbox?.Inbox?.APIAccount?.APIAccountId, folders: inboxFolders}
                    this.inboxes.push(myInbox)
                }
            })
        }
        this.isLoading = false
        //console.log("finished loading stuff")
        //console.log("inboxes:", this.inboxes)
        //console.log("apiAccounts:", this.apiAccounts)
    }

    getInboxCount() {
        return this.inboxes.length;
    }

    getFolder(id: number) {
        const folder = this.folders.filter(x => x.folderId === id)
        if (folder && folder[0]) {
            return folder[0]
        }
        return null
    }
    getApiAccount(id: number) {
        const apiAccount = this.apiAccounts.filter(x => x.apiAccountId === id)
        if (apiAccount && apiAccount[0]) {
            return apiAccount[0]
        }
        return null
    }
    getInbox(id: number) {
        const inbox = this.inboxes.filter(x => x.inboxId === id)
        if (inbox && inbox[0]) {
            return inbox[0]
        }
        return null
    }
}

export class Inbox {
    inboxId: number
    name: string
    apiAccountId: number
    apiAccount: APIAccount
    folders: Folder[]

    constructor(id: number, name: string, apiAccountId: number, apiAccount: APIAccount, folders: Folder[]) {
        makeAutoObservable(this, { inboxId: false, name: false, apiAccountId: false, apiAccount: false, folders: false })
        this.inboxId = id
        this.name = name
        this.apiAccount = apiAccount
        this.apiAccountId = apiAccountId
        this.folders = folders
    }
}

export class APIAccount {
    apiAccountId: number
    name: string
    
    constructor(id: number, name: string) {
        makeAutoObservable(this, { apiAccountId: false, name: false })
        this.apiAccountId = id
        this.name = name
    }
}

export class Folder {
    folderId: number
    name: string
    isInbox: boolean
    isArchive: boolean
    isSpam: boolean
    inboxId: number 

    constructor(folderId: number, name: string, isInbox: boolean, isArchive: boolean, isSpam: boolean, inboxId: number) {
        makeAutoObservable(this, { folderId: false, name: false, isInbox:false, isArchive:false, isSpam: false, inboxId: false })
        this.folderId = folderId
        this.name = name
        this.isArchive = isArchive
        this.isInbox = isInbox
        this.isSpam = isSpam
        this.inboxId = inboxId
    }
}