import { ConversationStore } from "./conversationStore";
import { InboxStore } from "./inboxStore"

export class RootStore {
    inboxStore
    conversationStore
    constructor(userId: number) {
        this.inboxStore = new InboxStore(this)
        this.inboxStore.init(userId);
        this.conversationStore = new ConversationStore(this)
        this.conversationStore.init();
    }

    getInboxStore() {
        return this.inboxStore
    }
    getConversationStore() {
        return this.conversationStore
    }
}




