import { Formik } from "formik";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import Input from "../Input/input.component";
import { useToasts } from "react-toast-notifications";
import { ADD_INBOXES, ADD_TEAM, GET_DEPARTMENTS } from "./query";
import { useMutation } from "@apollo/client";
import { departmentValidationSchema } from "./validation-schema";
import { useState, useEffect, useContext } from "react";
import UserInboxSelect from "../UserInboxSelect/user-inbox-select.component";

const AddTeam = ({ isOpen, close }) => {
	const [addTeam, { data, loading, error }] = useMutation(ADD_TEAM);
	const [addInboxes] = useMutation(ADD_INBOXES)
	const { addToast } = useToasts();
	const [dirty, setDirty] = useState(false);
	const [inboxes, setInboxes] = useState([])

	useEffect(() => {
		setInboxes([])
	}, [isOpen])

	const submit = async ({ teamName }) => {
		let temp = await addTeam({
			variables: {
				teamName,
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_Department(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.insert_msgbox_Department,
								query: GET_DEPARTMENTS,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});
		for(let i = 0; i < inboxes.length; i++){
			let obj =  {
				inbox: inboxes[i].value,
				department: temp.data.insert_msgbox_Department_one.DepartmentId,
			}
			await addInboxes({
				variables: obj
			})
		}

		addToast("Successfully created team", {
			appearance: "success",
			autoDismiss: true,
		});
		closeModal();
	};

	const closeModal = () => {
		close();
		setDirty(false);
	};

	return (
		<MsgBoxModal
			isOpen={isOpen}
			cancel={closeModal}
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			header="Add A Team"
			description="Use the form below to add a team"
			onRequestClose={closeModal}
			submitId="add-team"
			submit={submit}
			submitting={loading}
			submitButtonDisabled={dirty === false}
		>
			<Formik
				initialValues={{
					teamName: ""
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={departmentValidationSchema}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({
					handleSubmit,
					values,
					errors,
					touched,
					validateForm,
					handleChange,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit} id="add-team">
						<Input
							placeholder="Team Name"
							required
							name="teamName"
							label="Team Name"
							onChange={(event) => {
								handleChange(event);
								setDirty(true);
							}}
							error={errors.teamName}
							value={values.teamName}
						/>
						<UserInboxSelect
							label="Inboxes"
							name="Inboxes"
							onChange={(e) => {
								setInboxes(e)
								setDirty(true);
							}}
							value={inboxes}
						/>
					</form>
				)}
			</Formik>
		</MsgBoxModal>
	);
};

export default AddTeam;
