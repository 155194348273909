import { useEffect, useState, useContext } from "react";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import client from "../../Apollo";
import {
	GET_USERS,
	GET_ASSIGNEE,
	ASSIGN_TO_CONVERSATION,
	GET_ALL_USERS,
} from "./query";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import { Container } from "./styles";
import ReactTooltip from "react-tooltip";
import { $reuseableDialog, $selectedConversation, $selectedInbox } from "../../store";
import ReactGA from "react-ga4";
import { UserContext } from "../../Providers/user-provider";
import useWebSocket from "react-use-websocket";

const UserAssign = () => {
	const { addToast } = useToasts();

	const [users, setUsers] = useState([]);
	const [assignee, setAssignee] = useState(null);
	const selectedConversation = useReactiveVar($selectedConversation);
	const selectedInbox = useReactiveVar($selectedInbox);
	const userContext = useContext(UserContext)

	const [updateConversationAssignee, { loading, data, error }] = useMutation(
		ASSIGN_TO_CONVERSATION,
		{
			update(cache, data) {
				cache.modify({
					fields: {
						msgbox_Conversation(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.update_msgbox_Conversation,
								query: GET_ASSIGNEE,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		}
	);

	const { lastMessage } = useWebSocket(
		`${process.env.REACT_APP_WSAPI_URL}?orgid=${userContext.orgId}&token=${localStorage.getItem("access_token")}`,
		{
			reconnectAttempts: 100,
			reconnectInterval: (attemptNumber) =>
				Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
			share: true,
			shouldReconnect: (closeEvent) => true,
			onError: handleConnectionFailure,
		}
	);
	function handleConnectionFailure() {
		$reuseableDialog({
			visible: true,
			title: "Lost connection to the server",
			body: "You must refresh the page or no new messages will show",
			onPress: () => window.location.reload(),
			button: "Refresh",
		})
	}

	useEffect(() => {
		if (lastMessage && lastMessage.data) {
			const incomingData = JSON.parse(lastMessage.data);
			if(incomingData.conversationId == selectedConversation && incomingData.type == "conversation_update") {
				loadAssignedUser()
			}
		}
	}, [lastMessage]);



	const loadAssignedUser = async () => {
		try {
			const response = await client.query({
				query: GET_ASSIGNEE,
				variables: {
					conversationId: selectedConversation,
				},
				fetchPolicy: "network-only",
			});

			const convo = response.data.msgbox_Conversation.map((convo) => ({
				ConversationId: convo.ConversationId,
				AssignedTo: convo.AssignedTo,
				AssignedToDepartment: convo.AssignedToDepartment,
				User: convo.User,
				Department: convo.Department,
			}));

			if (convo.length === 1) {
				const data = convo[0];

				if (data.User && data.AssignedTo !== null) {
					// Assigned to user
					setAssignee({
						value: data.AssignedTo,
						label: data.User.FirstName + " " + data.User.LastName,
					});
				} else if (
					data.AssignedToDepartment !== null &&
					data.Department
				) {
					// Assigned to department
					if (data.Department && data.Department.Name) {
						setAssignee({
							value: data.AssignedToDepartment,
							label: `Assigned to team (${data.Department?.Name})`,
						});
					} else {
						setAssignee({
							value: data.AssignedToDepartment,
							label: `Assigned to deleted team`,
						});
					}
				} else {
					// Unassigned
					setAssignee({
						value: null,
						label: "Unassigned",
					});
				}
			}
		} catch (error) {
			console.log("error", error);
		}
	};

	useEffect(() => {
		ReactTooltip.show();
		loadAssignedUser();
		fetchUsers();
	}, [selectedConversation]);

	const fetchUsers = async () => {
		const response = await client.query({
			query: GET_USERS,
			variables: { inboxId: selectedInbox}
		});
		//const users = await client.query({
		//	query: GET_ALL_USERS,
		//});
		const options = [];
		var departmentList = [];

		// first we need to go and get a unique list of all the departments that users in our inbox are in.
		response.data.msgbox_UserInbox.forEach((user) => {
			if (user.User.UserDepartments){
				user.User.UserDepartments.filter((d) => {
					if(d.Department.DepartmentInboxes.length == 0) return true
					let arr = d.Department.DepartmentInboxes.filter((i) => {
						return i.InboxId == selectedInbox
					})
					return arr.length > 0 ? true: false
				}).forEach((department) => {
					departmentList.push({Name: department.Department.Name, DepartmentId: department.Department.DepartmentId})
				})
			}
		});
		var departmentSet = departmentList.reduce((a, c) => { 	!a.find(v => v.DepartmentId === c.DepartmentId) && a.push(c);     return a; }, []);
		
		
		departmentSet.forEach(
			(department) => {
				// now we need a full list of users who are in this department
				var usersInDepartment = [];
				response.data.msgbox_UserInbox.forEach((user) => {
					if (user.User.UserDepartments) {
						//console.log("user.User?.UserDepartments", user.User.UserDepartments)
						user.User.UserDepartments.forEach((usersdepartment) => {
							//console.log("department.DepartmentId ", department.DepartmentId )
							//console.log("usersdepartment.Department.DepartmentId", usersdepartment.Department.DepartmentId)
							if (department.DepartmentId === usersdepartment.Department.DepartmentId) {
								usersInDepartment.push({ label: user.User?.FirstName + " " + user.User?.LastName, value: { type: "user", value: user.User?.UserId}})
							}
						});
					}
				});
				//console.log("usersInDepartment:", usersInDepartment);
				if (usersInDepartment.length > 0) {
					// Add the users to the dropdown
					const group = {
						label: department.Name,
						options: usersInDepartment
					};
					// Add the department
					group.options.push({
						label: `Assign to team (${department.Name})`,
						value: {
							type: "department",
							value: department.DepartmentId,
						},
					});
					options.push(group);
				}
			}
		);
		const usersNotInATeam = response.data.msgbox_UserInbox.map((user) => ({
				value: { type: "user", value: user.User.UserId },
				label: `${user.User.FirstName} ${user.User.LastName}`,
			}));
		options.push({
			label: "All Users",
			options: usersNotInATeam,
		});
		options.push({
			label: "Unassign",
			options: [
				{
					label: "Unassign this conversation",
					value: {
						type: "unassign",
						value: null,
					},
				},
			],
		});

		setUsers(options);
	};

	const handleAssigneeChange = async (option) => {
		ReactTooltip.hide();
		console.log("option.value.value:", option.value.value);
		if (option.value.value === null) {
			await updateConversationAssignee({
				variables: {
					conversationId: selectedConversation,
					userId: null,
					departmentId: null,
				},
			});
			addToast(`Conversation unassigned`, {
				appearance: "success",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Conversation",
				action: "ConversationUnassigned",
			});
		} else {
			await updateConversationAssignee({
				variables: {
					conversationId: selectedConversation,
					userId:
						option.value.type === "department"
							? null
							: option.value.value,
					departmentId:
						option.value.type === "department"
							? option.value.value
							: null,
				},
			});
			setAssignee(option);
			if (option.value.type === "department") {
				addToast(`Conversation assigned to team`, {
					appearance: "success",
					autoDismiss: true,
				});
			} else {
				addToast(
					`${option.label} has been assigned to this conversation`,
					{
						appearance: "success",
						autoDismiss: true,
					}
				);
			}
			ReactGA.event({
				category: "Conversation",
				action: "ConversationAssigned",
			});
		}
	};

	return (
		<Container data-tip="Assign this conversation to an individual user or a team">
			<MsgBoxSelect
				value={assignee}
				onChange={handleAssigneeChange}
				options={users}
				form={false}
				hasLabel={false}
			/>
			<ReactTooltip className="tooltip" effect="solid" />
		</Container>
	);
};

export default UserAssign;
