import { gql } from "@apollo/client";

export const LOAD_INBOXES = gql`
    query loadInboxes($userId: Int!) {
        msgbox_UserInbox(where: {UserId: {_eq: $userId}}) {
        InboxId
        UserId
        Inbox {
            InboxId
            Name
            Folders {
            FolderId
            IsArchive
            IsInbox
            IsSpam
            Name
            }
            APIAccount {
            APIAccountId
            Name
            }
        }
        }
    }
`;